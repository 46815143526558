.DropzoneWrapper {
  height: 135px;

  section {
    height: 100%;
    padding: 5px;
    > div {
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;

      &:first-child {
        z-index: 2;
      }
    }
  }
}