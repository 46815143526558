$activeColor: #1e82f9;
$activeGradient: linear-gradient(135deg, #3b5cff 0%, #1eb8f2 100%);
$editColor: #94cf94;
$dangerColor: #df5d5d;
$cancelGradient: linear-gradient(135deg, #666 0%, #999 100%);
$warningColor: #df5d5d;

.Table {
    clear: both;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    &__Row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: stretch;
        color: #555;

        &_Head {
            font-weight: 700;
            position: sticky;
            z-index: 2;
            top: 0;
            background: #fff;
            color: #333;
            .Table__Column {
                border-width: 1px 0 1px 1px; position: relative;
                padding-right: 12px;
                &_Sorting {
                    cursor: pointer;
                    position: relative;
                    &:after {
                        content: '';
                        opacity: 1;
                    }
                    &_Desc:after {
                        transform: rotate(-45deg);
                        bottom: 4px;
                        
                    }
                }
            }
        }
    }
    &__Column {
        padding: 5px 10px;
        box-sizing: border-box;
        background-clip: padding-box;
        border: 1px solid #ddd;
        border-width: 0 0 1px 1px;
        > div {
            background-clip: padding-box;
        }
        &_Sorting {
            background-color: transparentize(#1eb8f2, 0.95);
        }
        &_Sortable {
            cursor: pointer;
            transition: 0.2s linear;
            &:hover {
                background-color: transparentize(#1eb8f2, 0.8);
            }
            &:after {
                content: '';
                transition: .2s linear;
                border: 1px solid #1eb8f2;
                border-width:  0 0 2px 2px;
                display: block;
                position: absolute;
                width: 8px;
                height: 8px;
                transform-origin: center;
                transform: rotate(135deg);
                bottom: 2px;
                right: 4px;
                opacity: 0;
            }
        }
    }
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}
