$activeColor: #0db3ce;
.Popup {
    max-width: 1000px;
    width: 90vw;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}
.UploadButton {
    border: 1px solid $activeColor;
    padding: 20px;
    display: block;
    margin: 0 0 20px;
    background: transparent;
    color: $activeColor;
    line-height: 30px;
    font-size: 16px;
    border-radius: 4px;
    width: 250px;
    position: relative;
    cursor: pointer;
    &:active,
    &:focus,
    &:hover {
        border: 1px solid $activeColor;
        background: transparent;
        color: $activeColor;
    }
    &:before {
        transition: linear 0.2s;
        content: "+";
        display: block;
        margin-right: 5px;
        font-size: 30px;
        line-height: 30px;
        font-weight: 700;
        margin-top: -4px;
        width: 30px;
    }
    &:after {
        transition: linear 0.2s;
        display: inline-block;
        word-break: keep-all;
        width: 200px;
        transform: translateY(-50%) translateX(-50%);
        position: absolute;
        top: 50%;
        left: 50%;
        content: "Select medline file";
    }
    &_Dragging {
        $blueColor: transparentize($activeColor, 0.9);
        background: repeating-linear-gradient(45deg, $blueColor, $blueColor 1px, white 2px, white 4px);
        &:before {
            margin: -4px auto 0;
            transform: scale(1.2);
        }
        &:after {
            transform: translateY(-50%) translateX(-50%) rotateY(90deg);
        }
    }
}

.SaveButton {
    background: $activeColor;
    border: none;
    border-radius: 3px;
    display: block;
    padding: 15px 20px;
    color: #fff;
    font-weight: 600;
    height: auto;
    cursor: pointer;
    &:focus {
        @extend .SaveButton;
    }
}
.FileInput {
    display: none;
}
.ResearchItems {
    max-height: calc(95vh - 220px);
    overflow-y: auto;
    width: 100%;

    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: transparentize($color: #0ba6bf, $amount: 0.5);
    }
    &::-webkit-scrollbar-track {
        border-radius: 2px;
        background: #eee;
    }
}
.ResearchItemPreview {
    margin: 10px 0;
    padding: 10px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    opacity: 0.5;
    transition: linear 0.1s;
    &:first-child {
        margin: 0;
    }
    &_Checked {
        opacity: 1;
    }
    &_Disabled {
        &:before {
            content: attr(data-text);
            font-size: 12px;
            color: red;
            font-weight: 500;
        }
        .Checkbox {
            cursor: default;
        }    
    }
    &__Title {
        width: 100%;
        font-weight: 600;
        vertical-align: middle;
        .Checkbox {
            vertical-align: middle;
            display: inline-block;
        }
    }
    &__Source {
        &::before {
            display: block;
            content: "Source";
            font-weight: 600;
            margin: 5px 0;
        }
    }
    &__Date {
        &::before {
            display: block;
            content: "Publication Date";
            font-weight: 600;
            margin: 5px 0;
        }
    }
    &__Authors {
        &::before {
            display: block;
            content: "Authors";
            font-weight: 600;
            margin: 5px 0;
        }
    }
    &__Author {
        background: transparentize($activeColor, 0.5);
        display: inline-block;
        margin: 0 5px 0 0;
        padding: 0 10px;
        border-radius: 3px;
    }
}

.Loading {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.2);
    cursor: wait;
}
