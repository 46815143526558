@import '../common/Table.module.scss';
@import '../common/Controls.module.scss';
.Head {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.PageContainer {
    padding: 70px 20px 20px;
    max-width: 1400px;
    margin: 0 auto;
}
.EditPage {
    max-width: 1400px;
    padding: 70px 30px 30px;
    margin: 0 auto;
}
.Column {
    width: 10%;
    &:first-child {
        width: 70%;
    }
    &:last-child {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        > a,
        > i {
            padding: 5px 10px;
            line-height: 1em;
            display: block;
            margin: auto 0;
            text-transform: capitalize;
            font-weight: 600;
        }
        i {
            font-style: normal;
        }
    }
}

.DeleteModal {
    padding: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background: #fff;
}

.Form {
    margin: 70px auto 0;
    &__Row {
        position: relative;
        padding: 10px 0 20px;
        label {
            width: 30%;
            display: inline-block;
        }
    }
    .Input {
        width: 70%;
    }
}
.Btn {
    margin: 0;
    &_Save {
        @extend .Btn;
    }
    &_Add {
        &_Author {
            @extend .Btn_Add;
            float: none;
            display: inline-block;
            transform: scale(0.8);
            transform-origin: top left;
        }
    }
}
.DropContainer {
    position: relative;
    height: 220px;
}

.Autocomplete {
    position: absolute;
    z-index: 5;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    max-width: 300px;
    overflow-y: auto;
    max-height: 300px;
    &__Item {
        padding: 10px 16px;
        cursor: pointer;
        &:hover {
            background: #f2fdff;
            color: #0db3ce;
        }
    }
    &__BlurHandler {
        position: fixed;
        z-index: 4;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

