$activeColor: #1e82f9;
$mainColor: #1e82f9;
$btnColor: #00bcd4;
$deleteColor: #db2828;
$saveColor: #00b5ad;
.Vote {
  background: #fff;
  margin: 0 0.5% 1%;
  padding: 40px 15px 35px 15px;
  border-radius: 3px;
  width: 24%;
  position: relative;
  opacity: 0.5;
  box-sizing: border-box;
  &:nth-child(4n + 1) {
    margin-right: 0;
  }
  &_approved {
    @extend .Vote;
    opacity: 1;
    border: 1px solid #c7eccb;
  }
}

.Vote__Actions {
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 5px;
}

.Previews {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  width: auto;
}

.Previews .Previews__row {
  display: flex;
  justify-content: flex-end;

  .CommentPreview, .CalculationMethodPreview {
    padding: 8px 0;
    cursor: pointer;
  }

  & > div {
    margin: 0 5px;
  }
}

.LinkPreview {
  padding: 3px;
  width: 20px;
  color: $activeColor;
  cursor: pointer;
}

.LinkIcon {
  $iconBaseSize: 9px;
  height: $iconBaseSize * 0.5;
  position: relative;
  width: 1px;
  margin: auto;
  background: $activeColor;
  &:before {
    position: absolute;
    right: 2px;
    top: -2px;
    content: "";
    display: inline-block;
    width: $iconBaseSize * 0.7;
    border: 1px solid $activeColor;
    height: $iconBaseSize;
    border-radius: $iconBaseSize/2 0 0 $iconBaseSize/2;
    border-right-color: transparent;
  }
  &::after {
    position: absolute;
    left: 2px;
    top: -2px;
    content: "";
    display: inline-block;
    width: $iconBaseSize * 0.7;
    height: $iconBaseSize;
    border: 1px solid $activeColor;
    border-radius: 0 $iconBaseSize/2 $iconBaseSize/2 0;
    border-left-color: transparent;
  }
}

.CitationPreview {
  padding: 3px 0;
  cursor: pointer;
}

.CitationIcon {
  height: 2px;
  background: #999;
  width: 12px;
  margin: auto;
  position: relative;
  top: 1px;
  border-radius: 1px;
  &:before,
  &:after {
    position: absolute;
    top: -3px;
    content: "";
    display: block;
    margin: auto;
    height: 2px;
    background: #999;
    width: 12px;
    border-radius: 1px;
  }
  &:after {
    top: 3px;
    width: 8px;
  }
}

.SourceIcon {
  position: relative;
  width: 16px;
  height: 11px;
  border: 1px solid $activeColor;
  overflow: hidden;
  &:after {
    position: absolute;
    display: block;
    content: "";
    bottom: 0;
    left: 20%;
    right: 0;
    background: $activeColor;
    height: 80%;
    clip-path: polygon(0% 90%, 30% 50%, 50% 70%, 80% 10%, 100% 30%, 100% 100%, 0% 100%);
  }
  &:before {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 1px;
    background: $activeColor;
  }
}

.SourceImage {
  position: relative;
  width: 400px;
  height: 300px;
  > div {
    height: 100% !important;
    width: 100% !important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0;
  }
}

.SourceFile {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 12px;
  border: 2px solid transparent;
  border-right: 0;
  border-top: 0;
  box-shadow: 0 0 0 2px #54B3CB;
  border-radius: 1px;
  border-top-right-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute
  }
  &::before {
    background: #54B3CB;
    box-shadow: 0 -3px 0 0 #54B3CB;
    left: 0;
    width: 6px;
    height: 1px;
    top: 8px;
  }
  &::after {
    width: 5px;
    height: 5px;
    border-left: 2px solid #54B3CB;
    border-bottom: 2px solid #54B3CB;
    right: -1px;
    top: -1px;
  }
}

.CommentIcon {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M11.4876 0.956299H1.73761C1.06547 0.956299 0.51886 1.53935 0.51886 2.2563V13.9563L3.76866 11.3563H11.4876C12.1598 11.3563 12.7064 10.7732 12.7064 10.0563V2.2563C12.7064 1.53935 12.1598 0.956299 11.4876 0.956299ZM11.4876 10.0563H3.36281L1.73761 11.3563V2.2563H11.4876V10.0563Z' fill='%23999999'/%3e%3c/svg%3e");
  height: 14px;
  width: 13px;
}

.CalculationMethodIcon {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.2619 0.956299H2.1508C1.35636 0.956299 0.70636 1.6063 0.70636 2.40074V12.5119C0.70636 13.3063 1.35636 13.9563 2.1508 13.9563H12.2619C13.0564 13.9563 13.7064 13.3063 13.7064 12.5119V2.40074C13.7064 1.6063 13.0564 0.956299 12.2619 0.956299ZM12.2619 12.5119H2.1508V2.40074H12.2619V12.5119Z' fill='%23999999'/%3e%3cpath d='M3.09865 4.39676H6.70976V5.4801H3.09865V4.39676ZM7.97365 10.1962H11.5848V11.2795H7.97365V10.1962ZM7.97365 8.39065H11.5848V9.47399H7.97365V8.39065ZM4.36254 11.8212H5.44587V10.3768H6.89031V9.29343H5.44587V7.84899H4.36254V9.29343H2.91809V10.3768H4.36254V11.8212ZM8.76087 6.72954L9.7792 5.71121L10.7975 6.72954L11.5631 5.96399L10.5448 4.93843L11.5631 3.9201L10.7975 3.15454L9.7792 4.17287L8.76087 3.15454L7.99531 3.9201L9.01365 4.93843L7.99531 5.96399L8.76087 6.72954Z' fill='%23999999'/%3e%3c/svg%3e");
  height: 14px;
  width: 14px;
}

.confirmation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  &_confirmed {
    width: 100%;
    background: #78de78;
  }
  &_rejected {
    width: 100%;
    background: #ff0052;
  }
}

.confirmation__buttons {
  position: absolute;
  top: 0;
  left: 15px;
}

.confirmationsPopupTrigger {
  font-weight: 600;
  line-height: 1em;
  font-size: 12px;
  color: #444;
  text-align: center;
  border-bottom: 1px dashed #00bcd4;
  border-radius: 3px;
  padding: 1px 5px;
  cursor: pointer;
}

.confirmationsPopupContent {
  display: flex;
  min-width: 250px;

  &_col {
    width: 50%;

    &:first-child {
      border-right: 1px solid #fff;
    }

    &:last-child {
      padding-left: 14px;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.btn {
  border: none;
  border-radius: 2px;
  background: $btnColor;
  padding: 4px 5px;
  font-weight: 600;
  font-size: 12px;
  margin: 3px 8px 5px 0;
  color: #fff;
  word-break: break-word;

  cursor: pointer;
  &_confirm {
    @extend .btn;
  }
  &_confirm:disabled {
    opacity: 0.5;
  }
  &_save {
    @extend .btn;
    background: $saveColor;
  }
  &_cancel {
    @extend .btn;
  }
  &_delete {
    @extend .btn;
    background: $deleteColor;
  }

  &_reject {
    @extend .btn;
    background: $deleteColor;
  }
  &_reject:disabled {
    opacity: 0.5;
  }
  &_edit {
    @extend .btn;
    background: $btnColor;
    float: right;
    margin-right: 0;
    padding: 4px 20px;
  }
}

.whoApproved {
  padding: 3px 5px 5px;
  display: inline-block;
  vertical-align: top;
}

.EditVotePopup {
  min-width: 500px;
  label {
    font-size: 12px;
    line-height: 30px;
    width: 180px;
    font-weight: 600;
  }
  .btn_save,
  .btn_cancel {
    padding: 10px 30px;
    font-size: 14px;
    float: right;
  }
}

.AddReasonVotePopup {
  min-width: 500px;
  padding: 20px 0;

  label {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #495B76;
    margin-bottom: 4px;
  }

  .PopupRow {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    > div {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      textarea {
        padding: 10px 16px;
        min-height: 100px;
        border-radius: 8px;
        border: 1px solid #E6EDF9;
        box-sizing: border-box;
        width: 100%;
        outline: none;
        resize: vertical;
        font-family: Lato;
      }
    }
  }

  > .PopupDropzoneRow {
    .DropzoneWrapper {
      height: 65px;
      color: rgb(114, 114, 114);
      section > div {
        border-radius: 8px;
      }
    }
  }

  > .PopupVoteButtons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-top: 32px;

    .btn_edit,
    .btn_rejected,
    .btn_cancel {
      flex: 1;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      color: #FFFFFF;
      padding: 9px 0;
      border-radius: 4px;
      margin: 0;
      border: 1px solid #c7eccb;
      cursor: pointer;
    }

    .btn_rejected {
      background: $deleteColor;
    }

    .btn_edit {
      background: #78de78;
    }

    .btn_cancel {
      background: $mainColor;
    }
  }
}

.EditRow {
  display: flex;
  padding: 10px 0;
  font-weight: 600;
  font-size: 16px;
  > div {
    font-size: 12px;
    line-height: 30px;
    width: 100%;
    input,
    textarea {
      padding: 10px;
      border-radius: 3px;
      border: 1px solid $activeColor;
      box-sizing: border-box;
      width: 100%;
      outline: none;
      resize: vertical;
    }
  }
  .ValueDropdown {
    position: relative;
    width: 100%;
    line-height: 20px;
  }
  .demoLabel::before {
    display: inline-block;
  }
}

.voteId {
  float: right;
  margin: 5px;
  font-size: 10px;
  font-weight: 500;
  color: #999;
}

.DropzoneRow {
  margin: 10px 0;
}

.DropzoneWrapper {
  position: relative;
  width: 100%;
  height: 210px;
  padding: 3px;
  img {
    max-height: 100%;
    max-width: 100%;
    width: auto !important;
  }

  section {
    height: 100%;
    > div {
      width: 100% !important;
      height: 100%;
      margin: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      &:first-child {
        z-index: 2;
      }
    }
  }
}

.EditVoteButtons {
  &:after {
    display: block;
    clear: both;
    content: "";
  }
}

.Validation {
  color: $deleteColor;
  text-align: center;
  margin: 10px;
}

.demoLabel {
  &:before {
    content: "Genetic Ancestry: ";
  }
}

.sexLabel {
  &:before {
    display: inline-block;
    content: "Sex: ";
  }
}
