$mainColor: #1e82f9;
$btnColor: #00bcd4;
$deleteColor: #db2828;
$saveColor: #00b5ad;
.votes {
  padding: 30px;
  width: 100%;
  max-width: 1600px;
  h1 {
    text-align: center;
  }
  &_loading {
    &:after {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      background: rgba(0, 0, 0, 0.3);
      cursor: wait;
    }
  }
}

.Trait {
  font-weight: bold;
  padding: 0 10px;
}

.TraitRow {
  display: flex;
  border: 1px solid #ddd;
  border-width: 0 1px 1px;
  background: #fff;
  &:first-child {
    border-top-width: 1px;
  }
  &_approved {
    @extend .TraitRow;
    background: transparentize($color: $saveColor, $amount: 0.9);
  }
  &__Cell {
    flex-basis: 20%;
    padding: 5px;
    border-left: 1px solid #dedede;
    justify-content: space-between;
    word-break: break-word;
    &:first-child {
      border-left: none;
    }
    &_name {
      @extend .TraitRow__Cell;
      flex-basis: 20%;
    }
    &_confirmation {
      @extend .TraitRow__Cell;
      display: flex;
      flex-direction: column;
      flex-basis: 15%;
      justify-content: center;
      align-content: center;
    }
    &_actions {
      @extend .TraitRow__Cell;
      text-align: center;
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      flex-wrap: wrap;
      flex-basis: 10%;
      justify-content: center;
      align-content: center;
    }
    &_author {
      @extend .TraitRow__Cell;
      text-align: center;
      flex-basis: 10%;
    }
    &_approved {
      @extend .TraitRow__Cell;
      text-align: center;
      display: flex;
      flex-basis: 10%;
      flex-direction: column;
      justify-content: space-around;
      > div {
        margin: auto;
      }
    }

    input {
      border: 1px solid #ddd;
      padding: 5px;
      width: 100%;
      min-width: 50px;
    }
  }
}

.label {
  display: inline-block;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin: 5px 0;
  padding-left: 50px;
  min-height: 1em;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    color: #666;
  }
  &_male {
    @extend .label;
    &:before {
      content: "male";
      font-weight: bold;
    }
  }
  &_female {
    @extend .label;
    &:before {
      content: "female";
      font-weight: bold;
      color: #666;
    }
  }

  &_citation {
    @extend .label;
    &:before {
      content: "Citation";
      font-weight: bold;
      color: #666;
    }
  }

  &_link {
    @extend .label;
    padding-left: 50px;
    &:before {
      content: "link";
    }
  }
}

.TraitHistory {
  padding: 10px;
  background: #f5f5f5;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
}

.btn {
  border: none;
  border-radius: 2px;
  background: $btnColor;
  padding: 5px 8px;
  margin: 5px;
  color: #fff;
  word-break: break-word;

  cursor: pointer;
  &_edit {
    @extend .btn;
  }
  &_save {
    @extend .btn;
    background: $saveColor;
  }
  &_cancel {
    @extend .btn;
  }
  &_delete {
    @extend .btn;
    background: $deleteColor;
  }
}

.link {
  &:hover {
    text-decoration: underline;
  }
}

.whoApproved {
  font-weight: 500;
  line-height: 1em;
  font-size: 12px;
  margin: auto;
  color: #666;
}

.vote_citation {
  width: 100%;
  max-width: 100%;
  margin-top: 5px;
  margin-left: 3px;
}

.SearchBox {
  display: block;
  border: 1px solid #999;
  border-radius: 3px;
  margin: 20px 10px;
  padding: 10px;
  outline: none;
}

.authorTitle {
  margin: 20px 0;
  font-size: 16px;
  width: 100%;
  &:before {
    content: 'Votes from ';
    color: #999;
    font-weight: 500;
  }
}

.VoteFilters {
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px 10px;
  position: relative;
  &:before  {
    content: 'Filters';
    position: absolute;
    top: -30px;
    left: 20px;
    display: block;
  }
}
div.FiltersDropdown {
  display: inline-block;
  vertical-align: middle;
  width: 300px;
  margin: 0 0 0 10px;
}

.DropdownBlock {
  flex-basis: 33%;
  box-sizing: border-box;
  padding: 20px;
  vertical-align: middle;
}

.NothingFound {
  text-align: center;
  margin: 10px auto;
  font-weight: 600;
  color: #444;
}