.validationError {
    color: red;
    text-align: center;
}
.loading:after {
    content: '';
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background: rgba(0,0,0,.3);
    cursor: wait;
}