.link_editor,
.link_label {
    position: relative;
    .ui.input input {
        padding-right: 30px;
    }
}
.delete_link_btn {
    position: absolute;
    right: 10px;
    top: 14px;
    width: 20px;
    height: 20px;
    z-index: 2;
    transition: all linear 0.2s;
    cursor: pointer;
    &:before,
    &:after {
        content: "";
        height: 20px;
        transition: all linear 0.2s;
        width: 0;
        border: 1px solid #ddd;
        display: block;
        position: absolute;
        transform: rotate(45deg);
        transform-origin: center;
        left: 50%;
        top: 0;
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before,
        &:after {
            border-color: #1e82f9;
        }
    }
}
