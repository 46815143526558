$mainColor: #1e82f9;
$btnColor: #00bcd4;
$deleteColor: #db2828;
$saveColor: #00b5ad;

.genomic-index,
.gsbs-generator {
    width: 100%;
    padding: 50px;
    position: relative;
    transition: linear 0.3s;
    .gsbs_panel {
        border: 1px solid #d9f5d6;
        padding: 10px;
        margin: 0 0 30px;
        label {
            float: left;
            clear: left;
            padding: 5px 5px 0 0;
            &:after {
                content: ":";
            }
        }
        input {
            margin: 5px 0;
            display: inline-block;
        }
        button {
            margin: 0;
        }
    }
    button,
    .button {
        height: 30px;
        display: block;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 20px;
        bottom: 0;
        top: unset;
        font-weight: 500;
        border: 1px solid #dde2e5;
        border-radius: 3px;
        background-color: #fafbfb;
        min-width: 90px;
        cursor: pointer;
        position: relative;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;

        &.delete_index {
            background: rgb(223, 93, 93);
            color: #fff;
        }
        &:disabled {
            cursor: default;
        }
    }
    &.loading {
        filter: grayscale(1);
        &:after {
            content: "";
            cursor: wait;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.2);
            z-index: 9999999;
        }
        &:before {
            content: attr(data-status);
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translateX(-50%);
            color: #fff;
            z-index: 11;
        }
    }

    h1 {
        text-align: center;
    }

    input[type="file"] {
        display: none;
    }

    &_table {
        &_row {
            display: flex;
            border-top: 1px solid #ddd;

            &.last {
                background: #d9f5d6;
            }

            &.head {
                background: #eee;
                border-top: none;
            }
        }

        &_cell {
            padding: 10px;
            flex-basis: 10%;
            box-sizing: border-box;
            border-left: 1px solid #ddd;

            &:first-child {
                border-left: none;
            }
            &.id,
            &.embryos {
                flex-basis: 100px;
                flex-grow: 1;
                flex-shrink: 1;
            }
            &.gsbs,
            &.gain {
                flex-basis: 25%;
                flex-grow: 1;
            }

            &.center {
                text-align: center;
            }

            &.demographic,
            &.family_history {
                flex-basis: 200px;
                flex-shrink: 0;
                flex-grow: 1;
                .dropdown {
                    width: 100%;
                }
            }

            &.actions {
                flex-basis: 150px;
            }

            input[type="text"],
            input[type="number"] {
                border: 1px solid #dde2e5;
                height: 30px;
                line-height: 30px;
                padding: 5px;
            }
        }
    }

    &_delete-file {
        color: $deleteColor;
        font-weight: 600;
        cursor: pointer;
        margin: 5px 0 10px;
    }

    &_upload-date {
        color: #aaa;
        font-size: 12px;
        font-weight: 600;
        &:before {
            content: "Uploaded: ";
        }
    }
}

.download_blob_link {
    display: block;
    margin: 5px auto 15px;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
}

.GSBSUploadComponent {
    font-size: 16px;
    font-weight: 400;

    &-paragraph {
        color: #565656;
        margin-bottom: 56px;

        &:first-child {
            margin-bottom: 0;
        }
    }

    &-list {
        color: #565656;
    }

    &-btnGroup {
        display: flex;
        justify-content: space-around;

        button {
            border: 0;
            cursor: pointer;
            outline: none;
            border-radius: 4px;
            padding: 8px 31px;
            font-weight: 700;
        }

        &--primary {
            background: #78ce78;
            color: #fff;
        }
        &--secondary {
            color: #fff;
            background: #FF0000;
        }
    }
}
