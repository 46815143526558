.Btn {
    padding: 10px 20px;
    background: #64a8d2;
    border: none;
    border-radius: 3px;
    color: #fff;
    transition: 0.3s linear;
    float: none;
    cursor: pointer;
    font-style: normal;
    text-align: center;
    display: inline-block;
    &:hover {
        background: #27a5f3;
    }
    &:disabled {
        cursor: default;
        opacity: 0.7;
        background: #999;
        &:hover {
            opacity: 0.7;
            background: #999;
        }
    }
    &_Save {
        @extend .Btn;
        margin-right: 30px;
    }
    &_Cancel {
        @extend .Btn;
        background: #666;
        &:hover {
            background: #666;
        }
    }

    &_Delete {
        @extend .Btn;
        background: #fd7f7f;
        &:hover {
            background: #fd7f7f;
        }
    }

    &_Add {
        @extend .Btn;
        float: right;
        background: #03a9f4;
        &:before {
            content: '+';
            font-weight: 600;
            display: inline-block;
            font-size: 30px;
            line-height: 14px;
            vertical-align: middle;
            margin: 0 5px 0 -10px;
        }
    }
}

.Input {
    border: 1px solid #dde2e5;
    line-height: 1em;
    padding: 10px;
    outline: none;
    &_Error {
        border-color: #fd7f7f;
    }
}

.Validation__Error {
    position: absolute;
    bottom: 0;
    color: #fd7f7f;
    font-size: 12px;
    font-weight: 600;
    &::before {
        content: '*';
    }
}
