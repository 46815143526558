.regions-container {
    background-color: #ccc;
    position: relative;
    padding: 55% 0 0;
    overflow: hidden;
    height: 0;
    margin: 30px auto;
    &-wrapper {
        margin: 30px auto;
        max-width: 1000px;
    }
    .rsm-svg {
        position: absolute;
        top: 0;
        top: -10%;
    }
    .rsm-geography {
        outline: none;
        fill: #fff;
        stroke: #aaa;
        stroke-width: 0.3;
        cursor: pointer;
    }
    .rsm-geography__selected {
        fill: transparentize($color: #1e82f9, $amount: 0.9);
    }
    .rsm-marker {
        position: static;
    }
    .rsm-marker-icon {
        fill: #1e82f9;
        cursor: pointer;
        transition: 0.2s;
        position: relative;
        z-index: 1;
    }
    .rsm-marker-tooltip {
        fill: #fff;
        stroke: #999;
        position: relative;
        z-index: 2;
        text {
            fill: #000;
        }
        &__region {
            font-weight: 600;
        }
        &__value {
            fill: #1e82f9;
            text-align: right;
            font-weight: 600;
        }
        &_stick {
            fill: #fff;
            stroke: #999;
            position: relative;
            z-index: 2;
        }
    }
}
