.Popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    bottom: 100%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    z-index: 1000;
    &_Visible {
        bottom: 0;
        overflow: auto;
        opacity: 1;
        animation: showPopup linear 0.2s;
    }
}
.Background {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
}

.Content {
    margin: auto;
    z-index: 9999;
    position: relative;
    background: #fff;
    min-width: 300px;
    max-width: 90vw;
    max-height: 90vh;
    overflow: auto;
}
.Header {
    padding: 20px 80px 20px 20px;
    font-weight: 600;
    background: #f5f5f5;
    position: sticky;
    top: 0;
    &__Text {
        font-size: 16px;
    }
}
.CloseBtn {
    position: absolute;
    right: 10px;
    top: 50%;
    padding: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}
.Body {
    padding: 20px;
}

.Confirm {
    &_Text {
        margin: 20px 0;
    }
    &__Buttons {
        display: flex;
        margin: 30px 0 0;
        justify-content: center;
        &_Yes {
            background: #78ce78;
            border: none;
            color: #fff;
            padding: 5px 30px;
            border-radius: 3px;
            margin: 0 20px;
            cursor: pointer;
        }
        &_No {
            background: red;
            border: none;
            color: #fff;
            padding: 5px 30px;
            border-radius: 3px;
            margin: 0 20px;
            cursor: pointer;
        }
    }
}

@keyframes showPopup {
    0%,
    100% {
        bottom: 0;
    }
}
