$failColor: #db2828;
$successColor: #00bcd4;
$warningColor: #dae440;
.Item {
    display: flex;
    &:first-child {
        background: #f5f5f5;
    }
    div {
        width: 30%;
        min-width: 200px;
        padding: 5px;
        vertical-align: middle;
        &:last-child {
            text-align: right;
        }
    }
    img {
        width: 15px;
        vertical-align: middle;
    }
}
.NoData {
    text-align: center;
}
.Action {
    font-weight: 600;
    padding-right: 20px;
    &_APPROVE,
    &_CONFIRM,
    &_CREATE_FREQUENCY,
    &_ADD,
    &_RESTORED {
        @extend .Action;
        color: $successColor;
    }
    &_DENY,
    &_REJECT,
    &_DELETE,
    &_DELETE_FREQUENCY {
        @extend .Action;
        color: $failColor;
    }
    &_UPDATE,
    &_UPDATE_FREQUENCY,
    &_DELETED_AS_DUBLICATE {
        @extend .Action;
        color: $warningColor;
    }
}