.Status {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    &_Visible {
        display: flex;
    }
}

.Popup {
    text-align: center;
    transform: translateY(160px) translateX(8px);
    background: #FAFBFB;
    width: 280px;
    padding: 40px 20px 20px;
}

.Message {
    margin: 0 0 10px;
}

.Bar {
    width: 240px;
    background: #c6e8f5;
    height: 10px;
    border-radius: 2px;
    position: relative;
    transition: linear 0.3s;
    margin: 0 0 20px;
    opacity: 0;
    &__Value {
        height: 100%;
        background: #1E82F9;
        border-radius: 2px;
        transition: linear 0.3s;
    }

    &_Visible {
        opacity: 1;
    }
    &:after {
        content: attr(data-text);
        position: relative;
        margin: 5px 0 0;
        display: block;
        color: #999;
        opacity: .8;
        font-size: 12px;
        font-weight: 600;
    }
}
