.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
    padding-bottom: 150px;
}

.ui.button.gradient:hover {
    background: linear-gradient(to bottom right, #1eb8f2, #3b5cff);
    color: white;
}

.ui.button.gradient:focus {
    color: white;
}

.ui.button.gradient {
    height: 50px;
    line-height: 25px;
    border-radius: 26.5px;
    background: linear-gradient(to bottom right, #3b5cff, #1eb8f2);
    color: #FFFFFF;
    font-family: Raleway;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.93px;
}

.ui.button:not(.icon)>.icon:not(.button):not(.dropdown) {
    margin-right: 12px;
}

.ui.button.gradient.disabled-button {
    background: linear-gradient(to bottom right, #f5f5f5, #c5c5c5);
    color: #858585;
    pointer-events: none;
}

.ui.button.secondary-button {
    width: fit-content;
    margin: auto;
    line-height: 35px;
    border-radius: 26.5px;
    background: transparent;
    color: #1E82F9;
}

.ui.button.secondary-button.menu-button {
    line-height: 20px;
}

.ui.button.secondary-button.menu-button i {
    margin: auto!important;
}

.instructions {
    padding: 25px;
}

.instructions h2, .instructions p, textarea {
    font-family: Raleway;
    font-weight: normal;
    color: #000;
}

.instructions p {
    font-size: 17px;
    letter-spacing: 0.24px;
}

.background-logo svg {
    margin-top: 90px;
}

.mobile-content-section {
    padding-top: 50px;
}

.ui.grid.nav {
    position: absolute;
    width: 100%;
    height: 145px;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0;
}

.ui.grid.nav.mobile-nav {
    height: 70px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.ui.grid.shadowed-nav {
    position: relative;
    box-shadow: 0 5px 15px 0 rgba(218,218,218,0.62);
    background-color: #FFF;
}

.ui.menu.nav-menu {
    background-color: transparent;
    border: none!important;
    justify-content: flex-end;
}

.ui.menu.nav-menu-mobile {
    border: none!important;
}

.ui.grid.nav .pushable {
    width: 100%;
    height: 300px;
}

.ui.grid.nav .pushable.hidden {
    height: 70px;
    transition: height 0s;
    transition-delay: 1s;
}

.ui.menu.nav-menu-mobile h3 {
    padding: 15px;
}

.ui.grid.nav svg {
    width: 4rem;
}

.ui.grid.nav h3 {
    font-family: Raleway;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 1.14px;
    color: #1E82F9;
}

.ui.grid.nav h3.title {
    font-size: 1.4rem;
}


.ui.grid.nav .pusher h3.title {
    font-size: 1.5rem;
    margin: 17px;
    /* line-height: 50px; */
}

.video-wrapper {
    width: 100%;
    max-height: 700px;
    overflow: hidden;
}

.wrapper {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 170px;
    background-color: #F9FAFA;
}

@media all and (max-width: 1039px) {
    .wrapper {
        background-color: #FFF;
    }
}

@media all and (max-width: 959px) {
    .ui.grid.nav {
        position: relative;
    }

    .ui.grid.nav svg {
        width: 3rem;
    }

    .page-container {
        min-width: 0;
        position: relative;
        top: 30px;
        margin-top: 0;
        padding-bottom: 35px;
        max-width: 959px
    }
}
