.DropZone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 42px;
      height: 42px;
    }
  }

  div {
    display: flex;

    margin-bottom: 6px;

    img {
      margin-right: 8px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #495b76;

      span {
        color: #1e82f9;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  p {
    font-size: 12px;
    line-height: 18px;
    color: #8f96a2;
  }
}

.not_displayed {
  display: none;
}