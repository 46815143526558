.table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 540px;
  padding: 0 8% 0 25px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px gray;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: #1E82FA;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
      background: #1E82FA;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: center;
    position: sticky;
    top: 0;
    background-color: white;
    padding-top: 15px;
    padding-bottom: 5px;
    margin-bottom: 5px;

    .metric_block {
      color: black;
      cursor: unset;
    }

    .regions {
      margin-top: 11px;
      color: #717171;
    }
  }

  .metric_block {
    width: 12.275%;
    padding-right: 25px;

    cursor: pointer;
    color: transparent;

    &:hover {
      .activity_block {
        color: white;
      }
    }
  }

  .metric {
    max-width: 100%;
  }
  .scroll_chin {
    position: sticky;
    bottom: 0;
    height: 40px;
    width: 100%;
    background-color: white;
  }

  .regions {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    width: 200px;
    padding-left: 25px;
  }

  .trait_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;

    &:hover {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
      border-radius: 7px;
    }
  }
}

.toolbarContainer {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #4F4F4F;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  .toolbarBlock {
    display: flex;
    align-items: center;

    .confirmationCount {
      margin-right: 36px;
    }

    input[type="number"] {
      margin-right: 12px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      text-align: center;
      width: 38px;
      height: 30px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    input[type="checkbox"] {
      margin-right: 12px;
      border: 2px solid #6D6D6D;
      border-radius: 4px;
      width: 18px;
      height: 18px;
    }

    .help_icon {
      display: inline-block;
      border: 2px solid #B9B9B9;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-left: 10px;
      box-sizing: content-box;
      color: #B9B9B9;
      text-align: center;
      cursor: help;
      line-height: 14px;
      position: relative;
      top: -1px;

      &:after {
          content: "?";
          font-weight: 700;
          font-size: 12px;
          display: inline-block;
          margin-left: 1px;
      }
    }
  }
}

.activity_block {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  border: 1px solid white;
  width: 22px;
  height: 34px;
  border-radius: 3px;
}

.red {
  background: #DF5D5D;
}

.orange {
  background: #E08B3C;
}

.yellow {
  background: #DDE03C;
}

.blue {
  background: #67CBB3;
}

.green {
  background: #67CB67;
}

.gray {
  background: #CECECE;
}

.metric_popup {
  width: 800px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin: 20px 20px 40px 20px;

  .color_descriptions {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    padding-right: 20px;
    margin-bottom: 40px;

    .desc {
      display: flex;
      align-items: center;
      padding-bottom: 14px;

      .rectangle {
        border-radius: 2px;
        width: 20px;
        height: 10px;
        margin-right: 12px;
      }
    }
  }

  .activity_block {
    color: white;
  }

  .metric_columns {
    display: flex;
    justify-content: space-between;
    padding: 60px;
  }

  .region_chin {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    bottom: 0;
    background-color: white;
    >div {
      min-width: 110px;
      text-align: center;
      .sexes {
        display: flex;
        justify-content: center;
        padding-bottom: 12px;
        .sex {
          padding: 2px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          >div {
            margin-bottom: 4px;
            &:last-child {
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
}

.vote_popup {
  position: relative;
  width: 350px;
  max-height: 350px;
  overflow-y: auto;

  .edit_vote_link {
    position: absolute;
    top: -5px;
    right: 10px;
    width: 16px;

    .edit_btn {
      right: 0;
      margin: 0;
      background: transparent;
      border: unset;
      cursor: pointer;
    }
  }

  .edit_btn i {
    border: 1px solid #fff;
    display: block;
    width: 5px;
    height: 8px;
    border-radius: 1px;
    position: relative;
    transform: translateX(5px) rotate(45deg);
  }
  .edit_btn i:before {
    position: absolute;
    width: 1px;
    background: #fff;
    height: 100%;
    left: 1px;
    content: '';
  }
  .edit_btn i:after {
    position: absolute;
    width: 4px;
    height: 4px;
    border: #fff solid;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);

    left: 0;
    bottom: -3px;
    content: '';
  }
}
