.about_us {
    .card_content {
        padding: 14px 15px;

        p {
            line-height: 30px;
            margin-bottom: 30px;
        }

        a {
            color: #328DFA;
            font-weight: bold;
        }

        .team {
            display: flex;
            justify-content: center;

            .member {
                h3, p, a {
                    text-align: center;
                    margin: 0;
                }

                .member_card {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-sizing: border-box;
                    width: 400px;
                    height: 412px;
                    border: 1px solid #D8D8D8;
                    border-radius: 9px;
                    background-color: #FFFFFF;
                    margin: 22px;
                    padding: 30px;

                    img {
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        margin-bottom: 30px;
                    }

                    p.description {
                        font-size: 13px;
                        line-height: 24px;
                        margin-top: 30px;
                    }

                    a.get_in_touch {
                        position: absolute;
                        bottom: 35px;
                        font-size: 13px;
                        font-weight: bold;
                        letter-spacing: 0.93px;
                        text-transform: uppercase;
                        font-family: Raleway;
                    }
                }
            }
        }
    }
}

@media all and (max-width: 900px) {
    .about_us {
        .card_content {
            .team {
                flex-direction: column;
                align-items: center;
            }
        }
    }
}

@media all and (max-width: 430px) {
    .about_us {
        .card_content {
            .team {
                .member .member_card {
                    width: 100%;
                    height: 440px;
                    margin: 30px 0;
                }
            }
        }
    }
}