.range_input {
    box-sizing: border-box;
    height: 30px;
    position: relative;
    user-select: none;
    cursor: pointer;
    &:after {
        box-sizing: border-box;

        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        top:14px;
        border: 1px solid #1e82f9;
        border-width: 1px 0;
        z-index: 0;
        border-bottom-color: #b1d0f4;
    }
    &.invalid {
        .range_input-second_part,
        .range_input-runner {
            border-color: red;
            background: red;
        }
    }
    &-second_part {
        box-sizing: border-box;

        content: "";
        position: absolute;
        height: 1px;
        top:14px;
        border: 1px solid #96c3f8;
        border-width: 1px 0;
        z-index: 1;
        right: 0;
        border-bottom-color: #b1d0f4;
    }
    &-runner {
        height: 13px;
        width: 13px;
        background-color: #1e82f9;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        z-index: 2;
        margin: 0 0 0 -6px;
        transition: all .1s linear;
        font-family: Raleway;
    }
    &-min,&-max {
        position: absolute;
        top: 25px;
        font-size: .9em;
        opacity: .6;
        left: 0;
        font-weight: 600;
        font-family: Raleway;
    }
    &-max {
        left: unset;
        right: 0;
    }
    &.disabled {
        filter: grayscale(1);
        opacity: .4;
        cursor: default;
        user-select: none;
    }
    .value {
        font-size: .9em;
        font-weight: 600;
        position: absolute;
        left: 50%;
        bottom: 17px;
        line-height: 1em;
        background: #fff;
        padding: 5px;

        transform: translateX(-50%);
        text-align: center;
        box-sizing: border-box;
        user-select: none;
    }
}
