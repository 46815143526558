@import '../common/Table.module.scss';
@import '../common/Controls.module.scss';
.Settings {
    display: block;
    padding: 80px 20px 20px;
    margin: auto;
    max-width: 1200px;
}
.Dropdown {
    max-width: 400px;
}
.Loading:after {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    cursor: wait;
    content: '';
}

.Title {
    font-size: 28px;
    font-weight: bold;
    color: #2b384f;
    text-align: left;
    &_Small {
        @extend .Title;
        font-size: 20px;
        margin: 50px 0 20px;
    }
}
.List {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a {
        color: #0ba6bf;
        font-size: 1.3em;
        background: #ddd;
        padding: 30px;
        border-radius: 5px;
    }
}

.EditRow {
    margin: 20px 0;
    label {
        display: inline-flex;
        align-items: center;
    }
}

.Checkbox {
    display: inline-block;
}

.Table {
    width: 100%;
    &__Column {
        width: 80%;
        &:last-child {
            width: 20%;
            text-align: right;
        }
        .Btn {
            height: auto;
            padding: 5px 10px;
        }
    }
}

.GenomicIndex, .Tabs {
    margin-top: 50px;
}

table {
    width: 100%;
    tbody {
        @extend .Table;
    }
    tr {
        @extend .Table__Row;
    }
    th,td {
        text-align: center;
        @extend .Table__Column;
        &:first-child {
            text-align: left;
        }
        &:last-child {
            text-align: right;
            >label {
                display: inline-flex;
                align-items: center;
                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }
}