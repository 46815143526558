.add_trait_popup {
    max-width: 400px;
    padding: 20px;
    &:after {
        content: '';
        display: block;
        clear: both;
    }
    &_container {
        max-width: 400px;
    }
    input, textarea {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ddd;
        margin: 0 0 20px;
        box-sizing: border-box;
        width: 100%; 
    }
    .button {
        margin: 20px 0 0;
        min-width: 100px;
        float: right;
        &.disabled_btn {
            filter: grayscale(1);
            pointer-events: none;
        }
    }
    .add_trait_popup__validation {
        color: red;
        margin: 10px 0 -10px;
        font-size: 12px;
        min-height: 20px;
    }
}