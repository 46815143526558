.dropdown {
    position: relative;
    width: 30%;
    user-select: none;
    min-height: 40px;
    &_list {
        height: 0;
        position: absolute;
        top: 100%;
        overflow-y: hidden;
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
        border-radius: 0 0 3px 3px;
        z-index: 100;

        &_item {
            padding: 8px 10px;
            user-select: none;
            cursor: pointer;
            &:hover,&__selected {
                background: #eee;
            }
        }

        &_trait {
            padding: 10px 20px;
            display: inline-block;

            &_title {
                display: inline-block;
                padding: 0 10px;
                vertical-align: top;
                cursor: pointer;
            }
        }
    }

    &_placeholder {
        color: #999;
        display: inline-block;
        padding-right: 5px;
    }

    &.expanded {
        .dropdown_list {
            z-index: 2;
            height: auto;
            max-height: 300px;
            z-index: 10;
            padding: 0;

            overflow-y: auto;
        }

        .dropdown_title {
            &:after {
                transform: translateY(-50%) rotate(-45deg);
            }
        }
        .dropdown_fake_background {
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    &_filters {
        display: flex;
    }

    &_title {
        border: 1px solid #dde2e5;
        padding: 10px 25px 10px 10px;
        border-radius: 3px;
        font-size: 1.1em;
        font-weight: 600;
        width: 100%;
        position: absolute;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: 40px;

        transition: linear 0.3s;
        cursor: pointer;

        &:after {
            display: block;
            border: 2px solid #4773f2;
            border-width: 2px 2px 0 0;
            content: "";
            width: 10px;
            height: 10px;
            transform: translateY(-50%) rotate(135deg);
            transform-origin: center;
            position: absolute;
            right: 8px;
            top: 50%;
            transition: linear 0.2s;
        }
        &__autoComplete {
            padding: 0;
        }
    }

    &_input {
        border: none;
        outline: none;
        padding: 10px 25px 10px 10px;
        position: absolute;
        width: 100%;
        background: none;
        &:focus,
        &:active {
            outline: none;
            border: none;
        }
    }
}
