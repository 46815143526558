$activeColor: #1e82f9;
$activeGradient: linear-gradient(135deg, #3b5cff 0%, #1eb8f2 100%);
$editColor: #94cf94;
$dangerColor: #df5d5d;
$cancelGradient: linear-gradient(135deg, #666 0%, #999 100%);
$warningColor: #df5d5d;
.StatsPage {
    padding: 40px;
    max-width: 1600px;
    margin: 80px auto;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    position: relative;
    &_loading:before {
        position: fixed;
        transition: all linear .3s;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba( #000000, $alpha: .2);
        content: '';
        z-index: 10000;
    }

    h1 {
        font-size: 32px;
        color: #1e82f9;
        letter-spacing: 2.29px;
        font-weight: 500;
        &:after {
            display: block;
            clear: both;
            content: '';
        }
    }
    button {
        cursor: pointer;
        border: none;
        border-radius: 3px;
        color: #fff;
    }
}
.Head {
    z-index: 10;
    .Column_disease {
        z-index: 11;
        background: #fff;
    }
}
.Column {
    &_disease {
        width: 12%;
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;

    }
    &_number {
        width: 7%;
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        &_AUC {
            display: flex;
            width: 8.6%;
            flex-direction: column;
            justify-content: flex-start;
        }

        &_cost {
            width: 9.5%;
        }

    }
    &_head {
        position: relative;
        padding-bottom: 30px !important;
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.AUC_input {
    width: 100%;
    margin: auto;
}

.Dropdown {
    margin-right: 15px;
}
.OldVersionIcon {
    width: 24px;
    height: 24px;
    margin-right: 15px;
}

.Utility_Block {
    margin-bottom: 10px;
    display: flex;
}

@media screen and (max-width: 1200px) {
    .Column {
        &_disease, &_number, &_head {
            word-wrap: break-word !important;
        }
    }
}

.Controls {
    align-items: center;
    margin-bottom: 30px;

    .Controls__row {
        align-items: center;
        margin-bottom: 10px;
        display: flex;

        .fillNA {
            display: flex;
            align-items: center;

            input {
                width: 18px;
                height: 18px;
            }

            label {
                margin-left: 7px;
                font-size: 16px;
            }
        }
    }
}

.Btn {
    cursor: pointer;
    background: $activeGradient;
    padding: 10px 20px;
    font-weight: 600;
}



.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}


.ApiLink {
    float: right;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: normal;
}

.NumberLine {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    clear: both;
    font-size: 12px;
    line-height: 1.3em;
    color: #333;
    font-weight: 600;
    label {
        text-align: left;
        float: left;
        color: #666;
        font-weight: 500;
    }
}

.NA {
    color: #aaa;
    font-size: 8px;
    line-height: 1.3em;
}

.HelpIcon {
    display: inline-block;
    border: 1px solid #8f8f8f;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    box-sizing: content-box;
    color: #8f8f8f;
    text-align: center;
    cursor: help;
    line-height: 9px;
    position: relative;
    margin-left: 3px;
    z-index: 1;
    &:after {
        content: "?";
        font-size: 10px;
        display: inline-block;
        font-weight: 600;
        margin-left: 1px;
    }
}
.Loader {
    z-index: 10000;
    position: fixed;
    &:after {
        position: fixed;
        z-index: 9999;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.2);
    }
}

.LinkPopup {
    &_Buttons {
        display: flex;
        justify-content: space-between;
        margin: 20px 0 0;
        button {
            border: none;
            border-radius: 3px;
            padding: 10px 20px;
            color: #fff;
            cursor: pointer;
            font-weight: 600;
        }
    }
    &_SaveBtn {
        background: $activeGradient;
        &__disabled {
           background: $cancelGradient;
           opacity: .8; 
        }
    }
    &_CancelBtn {
        background: $cancelGradient;
    }
    &_Input {
        width: 100%;
    }
    &_Validation {
        color: $warningColor;
        font-weight: 600;
        font-size: 12px;
        margin: 10px 0 0;
        height: 15px;
    }
}

.MainButtons {
    display: flex;
    align-items: center;
    margin-left: auto;
}