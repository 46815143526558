.User {
  display: flex;
  border-bottom: 1px solid #f3f3f3;
  color: #444;
  margin: 0 10px;
  &_Admin {
    @extend .User;
    font-weight: 600;
    background: #f9fdff;
    color: #111;
  }
  &_Head {
    border-bottom: 1px solid #888;
    color: #111;
    @extend .User;
    margin: 0;
    padding: 0 10px;
  }
  &_Inactive {
    @extend .User;
    background: #efefef;
    color: #999;
  }
  &:last-child {
    border-bottom: none;
  }
  &__Col {
    padding: 10px;
    flex-basis: 10%;
    text-overflow: ellipsis;
    user-select: none;
    overflow: hidden;
    &:last-child {
      text-align: right;
    }
    &_Sortable {
      cursor: pointer;
    }
    &_Sorted {
      background: rgba(103, 219, 243, 0.09);
    }
    &_Status {
      flex-basis: 10%;
      display: flex;
      align-items: center;
    }
    &_User {
      flex-basis: 38%;
    }
    &_Actions {
      flex-basis: 15%;
      display: flex;
      justify-content: space-between;
      align-content: flex-end;
      flex-direction: row-reverse;
    }
  }
}
.Users {
  width: 100%;
  box-sizing: border-box;
  padding: 50px;
  h1,
  p {
    text-align: center;
  }
  p {
    font-size: 14px;
    color: #aaa;
    padding: 20px;
  }
}

.loading {
  position: relative;
  &:after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    cursor: wait;
    z-index: 9999;
  }
}
.active {
  padding: 5px;
  border-radius: 1px;
  font-size: .8em;
  text-align: center;
  background: #86bb89;
  width: 60px;
  color: #fff;
  line-height: 1em;
  font-weight: 600;
  opacity: .6;
}
.inactive {
  @extend .active;
  background: #fd7f7f;
}

.ManagePanel {
  line-height: 28px;
  margin: 20px 0;
  vertical-align: top;
  * {
    line-height: 28px;
    vertical-align: top;
  }
}

.EditUser {
  margin: auto;
  background: #fff;
  border-radius: 3px;
  width: 340px;
  &__Background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.3);
  }

  &__Head {
    background: #83b7d8;
    color: #fff;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 3px 3px 0 0;
  }
  &__Content {
    padding: 20px;
  }
  &__Row {
    margin: 10px 0;
    position: relative;
    display: flex;
    align-items: center;
  }
  &__Label {
    display: inline-block;
    width: 100px;
    line-height: 38px;
    vertical-align: top;
  }
  &__Field {
    width: 200px;
    display: inline-block;
    div.dropdown {
      display: block;
      width: 100%;
      box-sizing: border-box;
    }
  }

  input {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    width: 100%;
    &.password {
      padding-right: 40px;
    }
  }
  &__Checkbox {
    margin-top: 5px;
    cursor: pointer;
  }
}

.Dropdown {
  width: 200px;
  display: inline-block;
}

.Btn {
  padding: 5px 20px;
  background: #64a8d2;
  border: none;
  border-radius: 3px;
  color: #fff;
  transition: 0.3s linear;
  float: none;
  cursor: pointer;
  &:hover {
    background: #27a5f3;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
    background: #999;
    &:hover {
      opacity: 0.7;
      background: #999;
    }
  }
  &_Save {
    @extend .Btn;
    margin-right: 30px;
  }
  &_Cancel {
    @extend .Btn;
    background: #666;
    &:hover {
      background: #666;
    }
  }

  &_Delete {
    @extend .Btn;
    background: #fd7f7f;
    &:hover {
      background: #fd7f7f;
    }
  }

  &_Add {
    @extend .Btn;
    float: right;
    background: #03a9f4;
    &:before {
      content: "+";
      font-weight: 600;
      display: inline-block;
      font-size: 30px;
      line-height: 14px;
      vertical-align: middle;
      margin: 0 5px 0 -10px;
    }
  }
}

.EditBtn {
  @extend .Btn;
}

.PaswordToggler {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 14px;
  top: 50%;
  cursor: pointer;
  border-radius: 9px 0;
  border: 1px solid #03a9f4;
  transform: rotate(45deg) translateY(-70%);
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 6px;
    height: 6px;
    margin: -3px;
    z-index: 1;
    background: #03a9f4;
    border-radius: 50%;
  }
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 3px;
    height: 3px;
    margin: -2px 0 0 -2px;
    z-index: 2;
    background: #fff;
    border-radius: 50%;
  }
}

.InvalidInput {
  border-color: red;
}
.validation {
  color: red;
  font-size: 12px;
  line-height: 1.2em;
  text-align: right;
}

@media all and (max-width: 1280px) {
  .User__Col {
    flex-basis: 20%;
    &_Status {
      flex-basis: 10%;
    }
    &_User {
      flex-basis: 30%;
    }
  }
  .Btn {
    &_Delete {
      padding: 5px 10px;
    }
  }
  .EditBtn {
    padding: 5px 10px;
  }
}
