.custom-checkbox {
    $size: 24px;
    display: inline-block;
    width: $size;
    height: $size;
    border: 1px solid #dde2e5;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    &_check {
        border: 1px solid #1e82f9;
        border-width: 0 2px 2px 0;
        width: 35%;
        height: 60%;
        transform: rotateZ(35deg);
        transition: opacity 0.1s linear 0s;
        left: 30%;
        top: 10%;
        position: relative;
        opacity: 0;
    }
    &.checked {
        .custom-checkbox_check {
            opacity: 1;
        }
    }
    &.disabled {
        filter: grayscale(1);
        background: #ddd;
    }
}
