.selectors {
  display: flex;
  justify-content: space-between;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;

  .selector {
    max-width: 260px;
    width: 100%;

    :global(.dropdown) {
      min-height: 50px;
    }

    :global(.dropdown_title) {
      padding: 12px 25px;

      &:after {
        right: 25px;
        top: 45%
      }
    }
  }

  .demographic_selector {
    :global(.dropdown_title) {
      padding: 12px;
      display: flex;
      align-items: center;
      height: 45px;
    }
    :global(.dropdown_input) {
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    :global(.dropdown_list) {
      margin-top: 8px;
    }
    :global(.demographic) {
        display: flex;
        padding: 12px 25px;
        font-size: 14px;
        line-height: 140%;
        input {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
          border: 2px solid #6D6D6D;
          border-radius: 4px;
          -moz-appearance:none;
          -webkit-appearance:none;
          -o-appearance:none;
          margin-top: -1px;
          margin-right: 12px;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
    }
  }
}

.chart {
  position: relative;
  margin-top: 30px;
  width: 95%;

  .color_help {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.02em;
    position: absolute;
    bottom: 50px;
    right: 0;

    .demographic_color {
      display: flex;
      align-items: center;
      padding: 6px;

      .color_line {
        height: 4px;
        width: 12px;
        margin-right: 12px;
      }
    }
  }
}