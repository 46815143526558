.dropdown.ess_field_sex-dropdown {
    width: auto;
    position: absolute;
    left: 10px;
    bottom: 5px;
    right: 20px;
    min-height: 20px;

    .dropdown_title {
        padding: 2px 20px 2px 2px;
        min-height: 20px;
        font-size: 10px;
        box-sizing: border-box;
        height: 20px;
        line-height: 15px;
        &:after {
            zoom: 0.5;
        }
    }
}
