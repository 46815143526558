.demographic {
    &_page {
        width: 100%;
        padding: 50px;
        position: relative;
        transition: linear 0.3s;
        &.loading {
            filter: grayscale(1);
            &:after {
                content: "";
                cursor: wait;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.4);
                z-index: 10;
            }
            &:before {
                content: attr(data-status);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);
                color: #fff;
                z-index: 11;
            }
        }
    }
    

    h1 {
        text-align: center;
    }

    &_table {
        &_row {
            display: flex;
            border-top: 1px solid #ddd;

            &.last {
                background: #d9f5d6;
            }

            &.head {
                background: #eee;
                border-top: none;
            }
        }

        &_cell {
            padding: 10px;
            flex-basis: 25%;
            box-sizing: border-box;
            border-left: 1px solid #ddd;

            &:first-child {
                border-left: none;
            }

            &.center {
                text-align: center;
            }

            &.actions {
                text-align: right;

                &-label {
                    text-align: center;
                }
            }

            button,
            .button {
                height: 30px;
                margin: 0 10px;
                display: inline-block;
                box-sizing: border-box;
                padding: 0 20px;
                font-weight: 600;
                border: 1px solid #dde2e5;
                border-radius: 3px;
                min-width: 90px;
                cursor: pointer;

                &.delete_index {
                    background: rgb(223, 93, 93);
                    color: #fff;
                }
                &:disabled {
                    cursor: default;
                }
            }

            input[type="text"] {
                border: 1px solid #dde2e5;
                height: 30px;
                line-height: 30px;
                padding: 5px;
                width: 100%;
            }
        }
    }

    &_checkbox {
        vertical-align: middle;
    }
    &_btn_edit {
        background: rgb(148, 207, 148);
        color: #fff;
    }

    &_btn_save {
        color: #fff;
        background: rgb(148, 207, 148);
    }
    &_btn_delete {
        color: #fff;
        background: rgb(223, 93, 93);
    }
    &_btn_cancel {
        background: rgb(241, 248, 147);
    }
}
