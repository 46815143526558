.table {
    display: flex;
    justify-content: space-between;
    font-family: 'Raleway', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    width: 100%;

    &.year_row {
        font-weight: 600;
        padding: 16px 50px;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    &.user_rows {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        background-color: #F9FDFF;

        .row {
            padding: 16px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }

    &.user_weekly_rows {
        .row {
            padding: 18px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            &.week_row {
                padding-left: 50px;
            }
        }
    }

    .row {
        display: flex;
        padding: 10px 6px;

        &.week_row,
        &.login_row {
            width: 17%;

            .arrow {
                background: none;
                color: inherit;
                font: inherit;
                cursor: pointer;
                outline: inherit;
                border: 0;
                border: solid #1E82F9;
                border-width: 0 2px 2px 0;
                width: 10px;
                height: 10px;
                position: relative;
                margin-right: 15px;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                top: 3px;

                &.arrow_down {
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                }
            }
        }
        &.submitted_votes_row {
            width: 15%;
        }
        &.user_confirmed_votes_row {
            width: 17%;
        }
        &.user_rejected_votes_row {
            width: 16.65%;
        }
        &.confirmations_row {
            width: 13.5%;
        }
        &.rejections_row {
            width: 12%;
        }
        &.voted_edited_row {
            width: 8.67%;
        }
    }
}

.table_header {
    border-bottom: 1px solid #888888;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;

    .help_icon {
        display: inline-block;
        border: 2px solid #1E82FA;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        margin-left: 10px;
        box-sizing: content-box;
        color: #1E82FA;
        text-align: center;
        cursor: help;
        line-height: 14px;
        position: relative;
        top: -3px;

        &:after {
            content: "?";
            font-weight: 700;
            font-size: 12px;
            display: inline-block;
            margin-left: 1px;
        }
    }
}
