$activeColor: #1e82f9;
$activeGradient: linear-gradient(135deg, #3b5cff 0%, #1eb8f2 100%);
$editColor: #94cf94;
$dangerColor: #df5d5d;
$cancelGradient: linear-gradient(135deg, #666 0%, #999 100%);
$warningColor: #df5d5d;
.Page {
    padding: 100px 40px 60px;
    max-width: 1600px;
    margin: auto;

    h1 {
        font-size: 32px;
        color: #1e82f9;
        letter-spacing: 2.29px;
        font-weight: 500;
        padding: 0 30px;
    }

    &_loading:after {
        position: fixed;
        display: block;
        z-index: 10000;
        background-color: rgba(0, 0, 0, 0.2);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
    }
}
.Content {
    padding: 30px;
    margin: 30px auto;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.Column {
    &_disease {
        width: 28%;
    }
    &_number {
        width: 12%;
    }
}

.TotalRow {
    font-weight: 600;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 2;
    .Column {
        &_disease {
            flex-basis: 64%;
            border-top: 1px solid #ddd;
        }
        &_number {
            border-top: 1px solid #ddd;
        }
    }
}

.Dropdown {
    width: 200px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 20px;
    z-index: 12;
}

.Controls {
    margin: 0 0 30px;
    display: flex;
    justify-content: space-between;
}

.Btn {
    cursor: pointer;
    background: $activeGradient;
    padding: 10px 20px;
    font-weight: 600;
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

.Title {
    color: $activeColor;
}

.Head {
    z-index: 10;
}

.TableWrapper {
    overflow-y: auto;
    max-height: 532px;
    transition: all linear 0.1s;
    &_Hidden {
        max-height: 0;
        opacity: 0;
    }
}

.RegionsContainer {
    padding: 20px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.RegionsTableContainer {
    background: #fefefe;
    position: relative;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    margin: 10px 0;
    padding: 20px;
    .Title {
        cursor: pointer;
        position: relative;
        z-index: 1;
        margin: 0;
        padding: 0 0 20px;
    }
    &:after {
        content: "";
        border: $activeColor 2px solid;
        border-width: 0 2px 2px 0;
        display: block;
        position: absolute;
        right: 20px;
        top: 22px;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        transition: transform linear 0.1s;
        z-index: 0;
    }
    &_Expanded:after {
        transform: rotate(-135deg);
    }
}

.RegionInfo {
    color: $activeColor;
    padding: 0 0 20px;
    font-size: 14px;
    font-weight: 600;
}

.ApiLink {
    font-weight: 700;
    float: right;
    line-height: 40px;
}

.MapContainer {
    max-width: unset;
    &__Dropdowns {
        display: flex;
        justify-content: space-between;
        margin: 20px auto;
        max-width: 640px;
        > div {
            width: 48%;
        }
    }
}

.FractionOfBirth {
    position: relative;

    &_Input {
        border: 1px solid #dde2e5;
        margin: 0 0 0 10px;
        padding: 10px 20px 10px 10px;
        outline-style: none;
        text-align: right;
    }
    &:after {
        content: "%";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        bottom: 0;
        right: 5px;
        color: #bbc3c9;
        font-weight: 600;
    }
}

.HelpIcon {
    display: inline-block;
    border: 1px solid #8f8f8f;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    box-sizing: content-box;
    color: #8f8f8f;
    text-align: center;
    cursor: help;
    line-height: 9px;
    position: relative;
    margin-left: 3px;
    z-index: 1;
    &:after {
        content: "?";
        font-size: 10px;
        display: inline-block;
        font-weight: 600;
        margin-left: 1px;
    }
}