.statistic {
    padding: 43px;
    max-width: 1600px;
    margin: 80px auto 50px auto;
    background: #fff;
    position: relative;
}

.statistic_tabs {
    display: flex;
    justify-content: center;
    font-family: 'Raleway', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #8C8C8C;
    margin-bottom: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .tab {
        margin: 0 80px;
        background: none;
        color: inherit;
        padding: 0 0 18px 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        border: 0;
        border-bottom: 2px solid transparent;

        &.active,
        &:hover {
            color: #1E82FA;
            border-bottom: 2px solid #1E82FA;
        }
    }
}
