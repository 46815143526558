.TraitsCategories {
    &_page {
        width: 100%;
        padding: 50px;
        position: relative;
        transition: linear 0.3s;
        &.loading {
            filter: grayscale(.5);
            &:after {
                content: "";
                cursor: wait;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
                z-index: 10;
            }
            &:before {
                content: attr(data-status);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);
                color: #fff;
                z-index: 11;
            }
        }
    }

    h1 {
        text-align: center;
    }

    &_table {
        &_row {
            display: flex;
            border-top: 1px solid #ddd;

            &.last {
                background: #d9f5d6;
            }

            &.head {
                background: #eee;
                border-top: none;
            }
        }

        &_cell {
            padding: 10px;
            flex-basis: 25%;
            box-sizing: border-box;
            border-left: 1px solid #ddd;

            &:first-child {
                border-left: none;
            }

            &.center {
                text-align: center;
            }

            &.icon {
                flex-basis: 10%;
                position: relative;
                display: flex;
                .drop-container {
                    width: calc(100% - 30px);
                    height: 0;
                    padding-bottom: calc(100% - 30px);
                    position: relative;
                    margin: auto;
                    .dropzone {
                        height: 100%;
                        z-index: 1;
                    }
                    .thumb {
                        background-repeat: no-repeat;
                        background-size: contain;
                        position: absolute;
                        top: 10px;
                        left: 10px;
                        right: 10px;
                        bottom: 10px;
                        background-position: center center;
                        z-index: 0;
                    }
                }
            }

            &.actions {
                text-align: right;
            }

            button,
            .button {
                height: 30px;
                margin: 0 10px;
                display: inline-block;
                box-sizing: border-box;
                padding: 0 20px;
                font-weight: 600;
                border: 1px solid #dde2e5;
                border-radius: 3px;
                min-width: 90px;
                cursor: pointer;

                &.delete_index {
                    background: rgb(223, 93, 93);
                    color: #fff;
                }
                &:disabled {
                    cursor: default;
                }
            }

            input[type="text"] {
                border: 1px solid #dde2e5;
                height: 30px;
                line-height: 30px;
                padding: 5px;
                width: 100%;
            }
        }
    }

    &_btn_edit {
        background: rgb(148, 207, 148);
        color: #fff;
    }

    &_btn_save {
        color: #fff;
        background: rgb(148, 207, 148);
    }
    &__btn_delete {
        color: #fff;
        background: rgb(223, 93, 93);
        &_cross {
            width: 16px;
            height: 16px;
            background: rgb(223, 93, 93);
            color: #fff;
            font-weight: 600;
            padding: 2px;
            line-height: 10px;
            font-size: 14px;
            text-align: center;
            position: absolute;
            top: 5px;
            right: 5px;
            border-radius: 3px;
            cursor: pointer;
        }
    }
    &_btn_cancel {
        background: rgb(241, 248, 147);
    }
}
