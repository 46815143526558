.page-container {
    .page-title {
        &-icon {
            width: 47px;
            height: 40px;
            margin-right: 24px;
            position: relative;
            top: 5px;
        }
    }
}

header {
    .logo_wrapper {
        display: flex;
        align-items: center;
        height: 100%;

        img {
            margin-right: 20px;
            height: 70px;
        }
    }

    .menu_toggler {
        position: absolute;
        height: 70px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-bottom: 10px;
        z-index: 90;
    }
}