.misery_weight {
    position: relative;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    .ui.dropdown > .text {
        color: #204156;
    }

    input,
    div.dropdown,
    textarea,
    .ui.selection.dropdown.diseases-dropdown {
        background-color: #fff;
        padding: 16px;
        border: 1px solid #dde2e5;
        border-radius: 4px;
        box-sizing: border-box;
        width: 100%;
        height: 53px;
        position: relative;

        i.icon.dropdown {
            position: absolute;
            display: block;
            top: 20px;
            right: 10px;
            padding: 0;
            margin: -3px 0 0 0;
        }

        .menu {
            min-width: 100%;
            max-height: 300px;
            overflow: auto;
        }

        &.gray {
            background-color: #f9fafa;

            i.icon.dropdown:before {
                border-color: #a3b1b9;
            }
        }

        &.invalid {
            border-color: red;
        }

        > .text {
            top: 3px;
        }
    }

    textarea {
        min-height: 100px;
    }

    p.intro-text {
        line-height: 30px;
    }

    .mw_section_divider {
        border-top: 1px solid #dde2e5;
        position: relative;
        width: calc(100% + 70px);
        left: -35px;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .non_resizable {
        resize: vertical;
    }

    .help_icon {
        display: inline-block;
        border: 1px solid #8f8f8f;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        box-sizing: content-box;
        color: #8f8f8f;
        text-align: center;
        cursor: help;
        line-height: 9px;
        position: relative;
        margin-left: 3px;

        &:after {
            content: '?';
            font-size: 10px;
            display: inline-block;
            font-weight: 600;
            margin-left: 1px;
        }
    }

    .ui.search.traits_search {
        max-width: 500px;
        border: none;
        box-shadow: none;
        background-color: #f9fafa;

        > input.search {
            padding: 1em;
            border-radius: 30px;
            height: 50px;
            border: 1px solid #96c8da !important;
            background: #fff !important;
        }

        > .text {
            top: 5px;
            font-size: 1.1em;
        }

        .visible.menu {
            border: 1px solid #96c8da !important;
            border-radius: 7px;
            margin-top: 20px;
        }

        .dropdown.icon {
            padding: 1.5em;
        }
    }

    .ui.button.new_trait_btn {
        height: 45px;
        margin: 0 10px;
    }

    .misery_weight_title {
        text-align: center;
        margin: 20px 0 40px;

        .question-icon-container {
            display: inline-flex;
        }

        h2 {
            margin: 0 10px;
            vertical-align: middle;
            height: 50px;
            line-height: 50px;
            font-weight: 700;
        }
    }

    .disease-group-icon {
        position: relative;
        vertical-align: middle;
        top: 0;
        right: 0;
    }

    .form_row {
        display: flex;
        width: 100%;
        margin: 30px 0 0;

        &.right_aligned {
            justify-content: flex-end;
        }

        &.block {
            flex-wrap: wrap;
        }
        .form_col {
            input, textarea, p {
                color: #204156;
                font-size: 14px;
                font-weight: 400;
                font-family: Lato;
            }
        }
    }

    .form_col {
        width: 25%;
        margin: auto 0;
        position: relative;

        &:nth-child(3) {
            width: auto;
        }

        .range_input {
            margin-top: 0px;
        }

        &.submit_col {
            text-align: right;

            button {
                min-width: 150px;
            }
        }

        i.question-icon {
            position: relative;
            width: 20px;
            top: 0;
            right: 0;
            margin-left: 16px;
        }

        &.label {
            text-align: right;
            position: relative;
            margin: auto 0;
            padding: 0;
            margin-right: 16px;
            display: flex;
            width: 36%;

            div.text,
            p.title {
                position: relative;
                margin: auto 0;
                width: 100%;
                text-align: right;
                color: #204156;
                font-size: 16px;
                font-weight: 400;
                font-family: 'Raleway';
            }

            div.text {
                margin: 0;
            }

            &.small {
                width: 20%;
                padding: 0 0 0 20px;
            }
        }

        &.field {
            width: 38%;
        }

        .question-icon-container {
            width: 100%;
            position: static;
            margin: auto 0;
            display: flex;
            align-items: center;
        }

        &.full {
            width: 100%;
            text-align: right;

            button.invert {
                width: 200px;
                display: inline-block;
                text-transform: uppercase;
            }
        }

        &.error {
            color: red;
            font-size: 16px;
            text-align: center;
        }

        &.double {
            width: 75%;
        }

        &.placeholder {
            position: relative;

            &:after {
                display: block;
                position: absolute;
                height: 30px;
                right: 15px;
                top: 15px;
                color: #999;
                font-size: 1.3em;
                z-index: 2;
            }

            &.dollar:after {
                content: '$';
            }

            &.percent:after {
                content: '%';
            }
        }
    }

    .link_col {
        width: auto;

        .add_btn {
            color: #1e82f9;
            font-weight: 600;
            text-transform: uppercase;
            padding: 12px 10px 10px;
            cursor: pointer;
            display: inline-block;
            transition: all linear 0.3s;
        }
    }

    .dropzone_col {
        width: 25%;

        .drop-container {
            height: 150px;
            width: 100%;
            position: relative;

            .dropzone {
                height: 150px;
                margin: 0;
                width: 100%;
                z-index: 2;
                cursor: pointer;
                background-color: transparent;
                display: flex;
            }

            .thumb {
                width: 100%;
                height: 150px;
                margin: auto;
                position: relative;
                top: 0;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                line-height: 1.3em;
                text-align: center;
                word-break: break-all;

                &.pdf {
                    height: auto;
                    padding: 0 10px;
                }
            }

            p {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .citation_col {
        width: 25%;

        textarea {
            max-width: 100%;
        }
    }

    button.button.invert,
    button.button.invert:active,
    button.button.invert:focus {
        background: transparent;
        color: #1e82f9;
        border: 2px solid #1e82f9;

        &.mono {
            filter: grayscale(1) brightness(1.5);
        }
    }

    .full-divider {
        border-top: 1px solid #ddd;
        margin: 0 -35px;
    }

    .demographic_dropdown {
        width: 100%;
        background-color: #fff;

        &.gray {
            background-color: #f9fafa;

            i.icon.dropdown:before {
                border-color: #a3b1b9;
            }
        }
    }

    .ui.dropdown {
        i.dropdown.icon:before {
            content: '';
            border: 1px solid #1e82f9;
            border-width: 0 0 2px 2px;
            width: 7px;
            height: 7px;
            display: block;
            margin-top: 2px;
            transform: rotateZ(-45deg);
            transform-origin: center;
            transition: transform linear 0.2s;
        }

        & > input.search {
            padding: 16px !important;
        }

        &.visible {
            > .dropdown.icon:before {
                transform: rotateZ(135deg);
                margin-top: 4px;
            }
        }
    }

    &.saving {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 10;
            background: transparent;
        }

        .misery_weight-save {
            position: relative;
            opacity: 0.6;

            &:before,
            &:after {
                position: absolute;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #1e82f9;
                top: 50%;
                left: 50%;
                content: '';
                animation: zoomBtn 1.4s linear infinite;
            }

            &:after {
                animation-delay: 0.7s;
            }
        }
    }

    &.submitted {
        h1 {
            text-align: center;
        }
    }

    &.loading {
        filter: grayscale(1);

        &:before {
            content: '';
            position: absolute;
            top: -40px;
            left: -35px;
            bottom: -30px;
            right: -35px;
            z-index: 10;
            background: rgba(0, 0, 0, 0.3);
            cursor: wait;
        }
    }

    .or {
        font-size: 1.3em;
        display: inline-block;
        margin-left: 10px;
    }

    .disease-group-icon {
        background: none;
        border: none;
    }
}

.misery_weight_submitted {
    > p {
        text-align: center;

        a {
            color: #425df3;
            font-weight: 700;

            &.button {
                color: #fff;
                display: block;
                background: linear-gradient(140.37deg, #425df3 0%, #56b2ed 100%);
                font-weight: 500;
                max-width: 150px;
                padding: 10px;
                border-radius: 25px;
                margin: 2em auto;
            }
        }
    }

    &_divider {
        text-align: center;
        border-top: 1px dashed #dde2e5;
        position: relative;
        margin: 3em;

        &:after {
            content: 'or';
            text-align: center;
            display: block;
            position: absolute;
            left: 50%;
            top: -3px;
            transform: translate(-50%, -50%);
            background: #fff;
            color: #8fa0aa;
            padding: 10px;
            font-size: 1.2em;
        }
    }
}

.mw_section_title {
    color: #060c26;
    font-family: Raleway;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 30px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;

    &_edit {
        @extend .mw_section_title;
        font-family: Lato;
        margin-top: 0;
    }
}

.bordered_section {
    border: 2px dashed #ddd;
    padding: 30px 30px 30px 10px;
    margin: 50px 0 0;
    position: relative;

    .form_row .form_col.field {
        width: 40%;

        textarea {
            max-width: 100%;
        }
    }

    &-title {
        position: absolute;
        top: -15px;
        margin: 0 0 -100px;
        z-index: 1;
        background: #fff;
        display: inline-block;
        padding: 5px 30px 10px 10px;
        font-weight: 600;
        font-size: 1.2em;
        color: #204156;

        .title {
            font-weight: inherit;
            color: inherit;
        }

        i.question-icon {
            right: -5px;
            top: 7px;
        }
    }
}

.centered_buttons {
    width: max-content;
    margin: auto;

    .ui.button {
        width: 200px;
        text-transform: uppercase;

        &:first-child {
            margin-right: 20px;
        }
    }
}

html,
body {
    scroll-behavior: smooth;
}

.source_screenshot_dropzone_wrapper {
    position: relative;

    .drop-container {
        width: 100%;
        height: 210px;
        padding: 3px;

        .dropzone {
            background: transparent;
            z-index: 100;
            margin: 3px 0;
            width: 100%;

            &.disabled {
                border: none;
                background: none;
            }
        }

        .thumb {
            width: 100%;
            height: 200px;
            margin: auto auto auto 3px;
            position: relative;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            &_loading {
                width: 100%;
                height: 200px;
            }
        }
    }
}

@keyframes zoomBtn {
    0% {
        transform: none;
        opacity: 1;
    }

    100% {
        transform: scale(20);
        opacity: 0;
    }
}