.AdminMenu {
    $color: #1e82f9;
    position: absolute;
    top: 146px;
    display: flex;
    right: 0;
    left: 0;
    z-index: 101;
    padding: 0 130px 0 0;
    background: #fcfcfc;
    box-shadow: 0 3px 5px rgba(111, 111, 100, 0.1);
    justify-content: flex-end;
    &__Link {
        padding: 10px 12px;
        color: #999;
        font-size: 1em;
        font-weight: 600;
        transition: linear 0.3s;
        &_active,
        &:hover {
            @extend .AdminMenu__Link;
            color: $color;
        }
    }
}
