.edit-traits {
    $padding: 50px;
    $innerPadding: 30px;
    $borderRadius: 10px;
    $controlsHeight: 50px;
    width: 100%;
    padding: $padding;

    h1 {
        text-align: center;
        padding: 0 200px;
    }

    .no-data {
        margin: 80px 0;
        text-align: center;
        font-weight: 800;
        color: #757575;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    }

    &_save-btn.ui.button {
        float: right;
        margin: 25px 0 0;
        position: relative;
        transition: 0.2s linear 0s;
        background: linear-gradient(135deg, #3b5cff 0%, #1eb8f2 100%);
        background-size: 200px 200px;
        background-position: 0 0;
        position: sticky;
        top: 20px;
        z-index: 7;

        &:active {
            transform: scale(0.97);
        }

        &:hover {
            background: linear-gradient(135deg, #3b5cff 0%, #1eb8f2 100%);
            background-size: 200px 200px;
            background-position: 0 -50px;
        }

        &.saving {
            color: transparent;
            opacity: 0.8;

            &:before,
            &:after {
                position: absolute;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #fff;
                top: 50%;
                left: 50%;
                content: "";
                animation: zoomBtn 1.4s linear infinite;
            }

            &:after {
                animation-delay: 0.7s;
            }
        }

        &.nothing-to-save {
            opacity: 0.5;
            cursor: default;
        }

        &.success {
            color: transparent;

            &:before {
                position: absolute;
                width: 20px;
                height: 10px;
                border: 2px solid #fff;
                border-width: 0 0 2px 2px;
                transform: rotate(-45deg);
                top: 50%;
                left: 50%;
                margin: -7px -10px;
                content: "";
            }
        }
    }

    &_delete-btn {
        background: #f56161;
        width: 15px;
        height: 15px;
        border-radius: 2px;
        position: absolute;
        top: 2px;
        right: 2px;
        &:before,
        &:after {
            display: block;
            content: "";
            position: absolute;
            left: calc(50% - 1px);
            top: 15%;
            height: 70%;
            width: 2px;
            background: #fff;
            transform: rotate(45deg);
            transform-origin: center;
            border-radius: 1px;
        }
        &:after {
            transform: rotate(-45deg);
            transform-origin: center;
        }
    }

    @keyframes zoomBtn {
        0% {
            transform: none;
            opacity: 1;
        }

        100% {
            transform: scale(20);
            opacity: 0;
        }
    }

    &_new-btn.ui.button {
        border: 2px solid #1683fc;
        color: #1683fc;
        background: none;
    }

    &_new-trait-modal {
        position: absolute;
        top: -100px;
        right: 0;
        background: #fff;
        z-index: 8;
        width: 400px;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);

        &.closed {
            display: none;
        }
    }

    &_container {
        background: #fff;
        border-radius: $borderRadius;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
        transition: 0.5s linear 0s;
        position: relative;

        &.loading,
        &.saving {
            &:before {
                border-radius: $borderRadius;
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
                background: rgba(0, 0, 0, 0.15);
                z-index: 20;
                cursor: wait;
                transition: 0.2s linear 0s;
            }

            &:after {
                content: "Loading...";
                z-index: 20;
                position: absolute;
                top: 50%;
                left: 50%;
                height: 20px;
                width: 100px;
                font-weight: 800;
                font-size: 1.3em;
                margin: -20px 0 0 -50px;
            }
        }

        &.saving:after {
            content: "Saving...";
        }
    }

    &_top-panel {
        padding: $innerPadding;
        border-radius: $borderRadius $borderRadius 0 0;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
        z-index: 5;
        background: #fff;
        &:after {
            content: "";
            display: block;
            clear: both;
        }
    }

    &_new-btn {
        float: right;
    }

    &_search-container {
        float: left;

        input {
            border: 1px solid #dde2e5;
            border-radius: 25px;
            height: $controlsHeight;
            box-sizing: border-box;
            padding: 25px 50px;
            outline: none;
            background: url(/images/icons/ic_search.svg) no-repeat 10px center;
        }
    }

    .ui.selection.categories-dropdown {
        max-width: 300px;
        margin: auto;
        display: block;
        border: none;
        min-height: $controlsHeight;
        font-size: 1.3em;
        z-index: 10;
        position: relative;
        user-select: none;
        border: 1px solid #dde2e5;
        border-radius: 25px;

        & > input {
            height: $controlsHeight;
            box-sizing: border-box;
            padding: 25px 50px;
        }

        & > .dropdown.icon {
            top: 10px;
            font-size: 20px;
        }

        & > .menu {
            border-radius: 0 0 25px 25px;
        }
    }

    &_table {
        display: grid;
        grid-auto-flow: column;
        position: relative;
        width: auto;

        &_container {
            overflow: auto;
            max-height: 70vh;
            min-height: 200px;
        }

        &_cell {
            position: relative;
            border: 1px solid #dde2e5;
            background: #fff;
            border-width: 1px 1px 1px 0;
            padding: $innerPadding/3;
            min-width: 200px;
            max-width: 100%;
            box-sizing: border-box;
            cursor: pointer;

            .opaciter {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                background: rgba(0, 0, 0, 0.05);
                display: none;
                z-index: 5;
            }

            &.name-cell {
                position: sticky;
                top: 0;
                z-index: 3;
            }

            &.editing {
                z-index: 6;

                input,
                textarea,
                .dropdown,
                .checkbox {
                    z-index: 7;
                    position: relative;
                }

                .opaciter {
                    display: block;
                }
            }

            .dropzone {
                width: 100%;
                height: 30px;
                margin: 0;
                box-sizing: border-box;
                padding: 0;
                position: absolute;
                bottom: 0;
                top: unset;
                border: 1px dashed #dde2e5;
                border-radius: 3px;
                background-color: #fafbfb;

                &:after {
                    content: "Upload file";
                    position: absolute;
                    line-height: 25px;
                    text-align: center;
                    width: 100%;
                }
            }

            .validation_error_text {
                color: red;
                font-size: 11px;
            }

            .upload_btn {
                border: 1px dashed #dde2e5;
                border-radius: 3px;
                background: #fafbfb;
                padding: 10px 25px 10px 10px;
                width: 100%;
                box-sizing: border-box;
                text-overflow: ellipsis;
                overflow: hidden;

                &:disabled {
                    opacity: 0.3;
                }
            }

            .json-drop-relative {
                .dropzone:after {
                    content: none;
                }

                .ui.dropdown {
                    opacity: 1;
                    border: 1px solid #999;
                    user-select: none;
                }

                .dropdown.disabled {
                    opacity: 0.3;
                }
            }

            .zone-filename {
                position: absolute;
                bottom: 6px;
                right: 40px;
                overflow: hidden;
                z-index: 2;
                text-overflow: ellipsis;
                text-align: center;
                left: 10px;

                &:hover {
                    display: none;
                }
            }

            .drop-container {
                box-sizing: border-box;
                z-index: 0;
                padding-bottom: 40px;
                position: relative;

                .thumbsContainer {
                    width: 100%;
                    z-index: 0;
                }

                .thumb {
                    max-width: 50px;
                    max-height: 50px;

                    img {
                        min-width: 30px;
                    }
                }
            }

            .ui.selection.dropdown {
                min-width: 0;
                width: 100%;
                box-sizing: border-box;
                margin: 5px 0;
            }

            &--text {
                min-height: 0;
                width: 100%;
                min-width: 100px;
            }

            &--dropdown.dropdown,
            &.ui.selection.dropdown {
                box-sizing: border-box;
                width: 100%;
                min-width: 50px !important;
                user-select: none;
            }
        }

        &_th {
            position: relative;
            z-index: 3;
            border: 1px solid #dde2e5;
            border-width: 1px 0;
            padding: $innerPadding/3;
            background: #fafbfb;
            color: #060c26;
            font-size: 13px;
            font-weight: 800;
            letter-spacing: 0.93px;
            line-height: 16px;
            word-break: keep-all;
            text-transform: uppercase;

            &:first-child {
                position: sticky;
                top: 0;
                z-index: 4;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                height: 100%;
                left: 0;
                width: 100%;
                background: #fafbfb;
                z-index: -1;
                box-shadow: 3px 0 5px 0 rgba(0, 0, 0, 0.05);
            }

            .help_icon {
                display: inline-block;
                border: 2px solid #8f8f8f;
                border-radius: 50%;
                width: 12px;
                height: 12px;
                box-sizing: content-box;
                color: #8f8f8f;
                text-align: center;
                cursor: help;
                line-height: 9px;
                position: relative;
                top: -2px;

                &:after {
                    content: "?";
                    font-size: 10px;
                    display: inline-block;
                    margin-left: 1px;
                }
            }
        }
    }

    &_disease-title {
        text-transform: uppercase;
        font-weight: 800;
    }

    &_fast-filters {
        clear: both;
        margin: 10px 0 0;
        padding: 5px 20px;
        border: 1px dashed #ddd;
        border-radius: 3px;
        float: left;

        > label {
            margin: 0 20px 0 0;
            font-weight: 600;
            line-height: 33px;
            vertical-align: middle;
        }
        > div {
            line-height: 25px;
            margin: 5px;
            vertical-align: top;
            display: inline-block;
        }
        .custom-checkbox {
            vertical-align: top;
        }
    }
}
.json-drop-relative {
    &__container {
        border: 1px dashed #ddd;
        margin: 5px 0;
        padding: 5px;
    }
}
.download-object-link {
    text-align: center;
    &__text {
        color: #3b5cff;
        border-bottom: 1px dashed #3b5cff;
        cursor: pointer;
        display: inline-block;
    }
    &__invisible {
        display: none;
    }
}
