.mw_dimmer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: #6d6d6d7a;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    .mw_loader_wrapper {
        width: 280px;
        height: 270px;
        background-color: #FAFBFB;
        display: flex;
        justify-content: center;
        align-items: center;

        .circle {
            border: 2px solid #DDE2E5;
            border-radius: 50%;
            position: absolute;
            width: 200px;
            height: 200px;
        }
    
        .ic_loader {
            width: 200px;
            height: 200px;
            margin-right: 0;

            .spinner {
                padding: 20px;
                fill: transparent;
                stroke-dasharray: 628;
                stroke: #4899F9;
                transform-origin: 50% 50%;
                animation: spin 2s ease infinite;
            }

            #moving_weight {
                transform-origin: 58px 10px;
                animation: balance 4s ease infinite;
            }

            #moving_weight_part_1 {
                transform-origin: 17px 5px;
                transform: translateX(0);
                animation: weight_part 4s ease infinite;
            }

            #moving_weight_part_2 {
                transform-origin: 100px 5px;
                transform: translateX(0) translateY(0);
                animation: weight_part 4s ease infinite;
            }
        }
    }
}

@keyframes spin {   
    0% {     
        stroke-dashoffset: 628;
        transform: rotate(-90deg);
    }   
    50% {     
        stroke-dashoffset: 0;  
        transform: rotate(-90deg);
    } 
    100% {
        stroke-dashoffset: 628;
        transform: rotate(270deg);
    }
}

@keyframes balance {   
    0% {     
        transform: rotate(-25deg);
    }
    50% {     
        transform: rotate(25deg);
    }
    100% {
        transform: rotate(-25deg);
    }
}

@keyframes weight_part {   
    0% {     
        transform: rotate(25deg) translateY(0px);
    }
    25% {     
        transform: rotate(-10deg) translateY(0px);
    }
    50% {     
        transform: rotate(-25deg) translateX(-2px) translateY(0px);
    }
    75% {     
        transform: rotate(10deg) translateY(0px);
    }
    100% {
        transform: rotate(25deg) translateY(0px);
    }
}