.misery_weight .misery_weight_chart {
    border: 1px solid #dde2e5;
    margin: 30px 0;

    &.expanded {
        .misery_weight_chart_body {
            max-height: 100%;
            padding: 30px 30px 30px 100px;
            overflow: visible;
        }
    }

    &_head {
        padding: 15px 30px;
        text-align: center;
        cursor: pointer;

        &_title {
            font-size: 1.5em;
            font-weight: 700;
            display: inline-block;
            padding: 0 30px;
            position: relative;
            max-width: 580px;

            i.question-icon {
                position: static;
                width: 20px;
            }

            &:after {
                display: block;
                border: 2px solid #4773f2;
                border-width: 2px 2px 0 0;
                content: "";
                width: 10px;
                height: 10px;
                transform: rotate(135deg);
                transform-origin: center;
                position: absolute;
                right: 8px;
                top: 5px;
                transition: linear 0.2s;
            }
        }

        &_description {
            text-align: left;
            margin: 30px 0 0 70px;
            font-size: 16px;
        }
    }

    &_body {
        max-height: 0;
        padding: 0;
        box-sizing: border-box;
        overflow: hidden;
        transition: linear 0.3s;
    }

    &_filters {
        display: flex;
    }

    &_container {
        height: 400px;
        margin: 3em 0;
        border: 1px solid #dde2e5;
        border-width: 0 1px;
        position: relative;
    }

    .avg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        &_line {
            stroke-width: 0.3;
            stroke: #666;
        }
    }

    &_column {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 20px;
        margin-left: -10px;
        user-select: none;
    }

    .dropdown {
        width: 30%;
        margin: 0 0 0 3%;
        padding: 0;
        border: none;
    }

    &_data {
        position: relative;
        height: 100%;
        width: 100%;
    }

    @mixin dotColor($color) {
        background: $color;
        //box-shadow: 0 0 10px transparentize($color, .2);
    }

    &_dot {
        position: absolute;
        @include dotColor(#51b451);
        width: 8px;
        height: 8px;
        transition: linear 0.2s;
        left: 50%;
        border-radius: 50%;
        z-index: 1;
        opacity: 0.5;
        padding: 5px;
        cursor: pointer;
        transform: translateY(50%) translateX(-50%);
        &.avg_dot {
            transform: translateY(50%) translateX(-50%) scale(1.5);
            box-shadow: 0 0 10px #51b451;
            opacity: 0.9;
            &.current {
                box-shadow: 0 0 10px #1e82f9;
            }
            &.notApproved {
                display: none;
            }
        }

        $blueColor: #1e82f9;
        $greenColor: #22ff0e;
        $yellowColor: #f5f820;
        $redColor: #f82020;

        &.current {
            background: $blueColor;
        }

        &.green {
            background: $greenColor;
        }

        &.yellow {
            background: $yellowColor;
        }

        &.red {
            background: $redColor;
        }

        &.blue {
            width: 4px;
            height: 4px;
            z-index: 2;
            background: $blueColor;
            opacity: 1 !important;
        }

        &:before {
            content: "";
            position: absolute;
            z-index: 1;
            top: -3px;
            left: -3px;
            width: 14px;
            height: 14px;
        }

        .part {
            position: absolute;
            width: 160%;
            height: 160%;
            left: -30%;
            top: -30%;
            border-radius: 50%;
            z-index: 0;
            &.main_part {
                z-index: 1;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0; 
            }
            &.green {
                background: $greenColor;
            }

            &.yellow {
                background: $yellowColor;
            }

            &.red {
                background: $redColor;
            }

            &.blue {
                background: $blueColor;
            }
            &_2 {
                &_0 {
                    clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
                }
                &_1 {
                    clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
                }
            }
            &_3 {
                &_0 {
                    clip-path: polygon(50% 0%, 100% 0%, 100% 70%, 50% 50%);
                }
                &_1 {
                    clip-path: polygon(100% 70%, 100% 100%, 0% 100%, 0% 70%, 50% 50%);
                }
                &_2 {
                    clip-path: polygon(0% 70%, 0% 0%, 50% 0%, 50% 50%);
                }
            }
        }
    }

    &_label {
        position: absolute;
        right: calc(100% + 5px);
        font-weight: 600;
        color: #999;
        font-size: 0.8em;
        transform: translateY(50%);
        text-align: right;
        width: auto;

        &.x_label {
            top: 408px;
            text-align: center;
            margin-left: -38px;
            transform: none;
            padding: 3px 10px;
            user-select: none;
            background-color: white;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            line-height: 1.3em;
            white-space: pre;
            z-index: 10;
            max-height: 60px;
            overflow-y: auto;
            right: unset;
            &::-webkit-scrollbar {
                width: 3px;
                border-radius: 1px;
            }

            &:hover {
                z-index: 900 !important;
                background-color: white;
                border-radius: 7px;
                -webkit-box-shadow: 6px 5px 15px 0px rgba(50, 50, 50, 0.63);
                -moz-box-shadow: 6px 5px 15px 0px rgba(50, 50, 50, 0.63);
                box-shadow: 4px 4px 9px 0px rgba(50, 50, 50, 0.63);
                transition: box-shadow 0.2s;
            }
        }
    }

    &_legend {
        position: absolute;
        transform: translate(-50%, -50%) rotate(-90deg);
        transform-origin: center;
        width: 100px;
        height: 100px;
        text-align: center;
        top: 50%;
        left: -30px;
        color: #999;
    }

    &_h_line {
        border-top: 1px solid #dde2e5;
        width: 100%;
        position: absolute;
        height: 0;
    }

    &_title {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 10px) rotate(-45deg);
        transform-origin: right;
        text-align: right;
        font-size: 0.7em;
        line-height: 1em;
        width: 50px;
        height: 50px;
    }

    &_dropdown {
        position: relative;
        width: 30%;
        user-select: none;
        height: 40px;

        &_list {
            height: 0;
            position: absolute;
            top: 100%;
            overflow-y: hidden;
            background: #fff;
            width: 333%;
            box-sizing: border-box;
            padding: 0;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
            border-radius: 0 0 3px 3px;
            z-index: 100;

            &_category {
                padding: 10px 0;

                &_title {
                    display: inline-block;
                    vertical-align: top;
                    padding: 0 10px;
                    font-weight: 900;
                    text-transform: uppercase;
                    cursor: pointer;
                }
            }

            &_trait {
                padding: 10px 20px;
                display: inline-block;

                &_title {
                    display: inline-block;
                    padding: 0 10px;
                    vertical-align: top;
                    cursor: pointer;
                }
            }
        }

        &.expanded {
            z-index: 5;

            .misery_weight_chart_dropdown_list {
                height: 300px;
                z-index: 10;
                padding: 30px;
                z-index: 5;
                overflow-y: auto;
            }

            .misery_weight_chart_dropdown_title {
                width: 333%;
                max-width: 333%;
                z-index: 5;
                background: #fff;

                &:after {
                    transform: rotate(-45deg);
                    top: 20px;
                }
            }

            .dropdown_fake_background {
                position: fixed;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        &_title {
            border: 1px solid #dde2e5;
            padding: 10px 20px 10px 10px;
            border-radius: 3px;
            font-size: 1.1em;
            font-weight: 600;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: keep-all;
            position: absolute;
            transition: linear 0.3s;

            cursor: pointer;

            &:after {
                display: block;
                border: 2px solid #4773f2;
                border-width: 2px 2px 0 0;
                content: "";
                width: 10px;
                height: 10px;
                transform: rotate(135deg);
                transform-origin: center;
                position: absolute;
                right: 8px;
                top: 15px;
                transition: linear 0.2s;
            }
        }
    }
}

.ui.inverted.popup.misery_weight_chart_dot_popup {
    display: flex !important;
    flex-direction: column;
    min-width: 400px;
    max-height: 500px;
    overflow-y: auto;
    z-index: 9999;

    &.small_dot_popup {
        overflow-y: hidden;
        min-width: auto;

        .misery_weight_chart_dot_popup_content {
            padding-right: 0;
        }
    }

    &.left {
        margin-left: -15px;
    }

    &.right {
        margin-right: -15px;
    }

    a {
        color: #fff;
        text-decoration: underline;
    }

    .misery_weight_chart_dot_popup_content {
        display: flex;
        position: relative;
        width: 100%;
        justify-content: space-between;
        padding-right: 45px;
        padding-bottom: 20px;
        z-index: 99999;
        &:last-child {
            padding-bottom: 0;
        }
        >p {
            margin: 0;
        }
        button {
            border-radius: 5px;
            font-weight: 600;
            background: transparent;
            cursor: pointer;
            border: 1px solid #333;
            border-radius: 3px;
            position: relative;
            right: 0;
            top: 2px;
            margin-left: 10px;
            width: 16px;
            height: 16px;
            display: block;
            padding: 0;
            &.clear-btn {
                position: absolute;
            }
        }
        .confirm-reject-btns {
            button {
                width: auto;
                padding: 3px 10px;
                color: #fff;
                height: auto;
                display: inline-block;
                font-size: 12px;
                margin: 10px 10px 10px 0;
                &.confirm-reject-btns_confirm {
                    background: #56b856;
                }
                &.confirm-reject-btns_reject {
                    background: #db2828;
                }
            }
        }
    }
    .edit-vote-link {
        position: absolute;
        right: 25px;
        width: 16px;
        .edit-btn {
            right: 0;
            margin: 0;
        }
    }
}

.screenshot_link {
    cursor: pointer;
    color: #1e82f9;
    display: block;
    &_line {
        @extend .screenshot_link;
        display: inline;
    }
}

.screenshot_modal {
    height: 600px;

    &_image {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &_loading {
        width: 100%;
        height: 100%;

        .ui.loader:after {
            border-color: black transparent transparent !important;
        }
    }
}
