$activeColor: #1e82f9;
$activeGradient: linear-gradient(135deg, #3b5cff 0%, #1eb8f2 100%);
$editColor: #94cf94;
$dangerColor: #df5d5d;
$cancelGradient: linear-gradient(135deg, #666 0%, #999 100%);
$warningColor: #df5d5d;
.RegionsPage {
    padding: 100px 40px 60px;
    max-width: 1600px;
    margin: auto;

    h1 {
        font-size: 32px;
        color: #1E82F9;
        letter-spacing: 2.29px;
        font-weight: 500;
    }
    button {
        cursor: pointer;
        border: none;
        border-radius: 3px;
        color: #fff;
    }
}

.RegionsTable {
    clear: both;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    &__Row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: stretch;
        color: #555;

        &_withoutCoordinates {
            opacity: .5;
            background-color: #efefef;
            color: #999;
        }

        &:first-child {
            font-weight: 700;
            color: #333;
            .RegionsTable__Column {
                border-width: 1px 0 1px 1px;
            }
        }
    }
    &__Column {
        padding: 5px 10px;
        box-sizing: border-box;
        background-clip: padding-box;
        border: 1px solid #ddd;
        border-width: 0 0 1px 1px;
        > div {
            background-clip: padding-box;
        }

        &_id {
            @extend .RegionsTable__Column;
            flex-basis: 8%;
        }
        &_name {
            @extend .RegionsTable__Column;
            flex-basis: 20%;
        }
        &_shortName {
            @extend .RegionsTable__Column;
            flex-basis: 15%;
        }
        &_demos {
            @extend .RegionsTable__Column;
            flex-basis: 20%;
        }
        &_population {
            @extend .RegionsTable__Column;
            flex-basis: 12%;
        }
        &_births {
            @extend .RegionsTable__Column;
            flex-basis: 10%;
        }
        &_actions {
            @extend .RegionsTable__Column;
            flex-basis: 15%;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                margin: 0 5px;
                border: none;
                border-radius: 3px;
                padding: 5px 10px;
                color: #fff;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }
}

.AddRegionBtn {
    background: $activeGradient;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    margin: -50px 0 20px;
    float: right;
    font-weight: 600;
    cursor: pointer;
}

.EditRegion {
    min-width: 700px;
     .RegionsTable__Row {
        margin: 5px 0;
        > .RegionsTable__Column {
            padding: 5px 0;
            background-color: transparent;
            border: none;
            font-weight: 500;
            &:last-child {
                padding-right: 0;
            }
            input {
                width: 100%;
                box-sizing: border-box;
                border-radius: 2px;
                border-color: #ddd;
            }
            &_label {
                width: 33.3%;
            }
            &_value {
                width: 33.3%;
                padding: 0 10px;
                
            }
            &_source {
                width: 33.3%;
                padding: 0 10px;
                &Big {
                    padding: 0 10px;
                    width: 66.6%;
                }
            }
        }
    }

    &__Buttons {
        display: flex;
        justify-content: space-between;
        margin: 20px 0 0;
        button {
            border: none;
            border-radius: 3px;
            padding: 10px 20px;
            color: #fff;
            cursor: pointer;
            font-weight: 600;
        }
    }
    &__SaveBtn {
        background: $activeGradient;
    }
    &__SaveBtn:disabled {
        background: $cancelGradient;
    }
    &__CancelBtn {
        background: $cancelGradient;
    }
    &__Demographics {
        margin: 30px 0;
        &__DeleteBtn {
            background: $dangerColor;
            border: none;
            border-radius: 3px;
            padding: 6px 20px;
            color: #fff;
            box-sizing: border-box;
            font-weight: 600;
        }
        input {
            border: 1px solid #aaa;
            margin: 0 0 0;
            padding: 5px;
            box-sizing: border-box;
        }

        &__Item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 5px 0;
            >div {
                flex-basis: 33.3%;
                flex-shrink: 0;
                &:last-child {
                    display: flex;
                    justify-content: flex-end;
                }
                input {
                    margin-left: 10px;
                }
            }
                
        }
    }
    button {
        cursor: pointer;
        border: none;
        border-radius: 3px;
        color: #fff;
    }
}
.EditRegionBtn {
    background: $editColor;
}
.RemoveRegionBtn {
    background: $dangerColor;
}

input {
    border: 1px solid #666;
    padding: 5px;
    border-radius: 3px;
}

.Warning {
    color: $dangerColor;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}
.Dropdown {
    width: 56%;
    margin: 0;
    >div:first-child+div {
        position: relative;
    }
}
