.choose_loader_wrapper {
    width: 280px;
    height: 270px;
    border: 1px solid #F1F1F1;
    border-radius: 50%;
    background-color: #FFF;
    box-shadow: 0 5px 12px 0 rgba(0,0,0,0.11);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    margin-left: -140px;
    top: 50%;
    margin-top: -135px;

    .circle {
        border: 2px solid #DDE2E5;
        border-radius: 50%;
        position: absolute;
        width: 200px;
        height: 200px;
    }

    .ic_scales {
        width: 200px;
        height: 200px;
        margin-right: 0;

        .spinner {
            padding: 20px;
            fill: transparent;
            stroke-dasharray: 628;
            stroke: #4899F9;
            transform-origin: 50% 50%;
            animation: spin 2s ease infinite;
        }

        #moving_scales {
            transform-origin: 58px 10px;
            animation: balance 4s ease infinite;
        }

        #moving_scales_part_1 {
            transform-origin: 17px 5px;
            transform: translateX(0);
            animation: scales_part 4s ease infinite;
        }

        #moving_scales_part_2 {
            transform-origin: 100px 5px;
            transform: translateX(0) translateY(0);
            animation: scales_part 4s ease infinite;
        }
    }
}

.default_loader {
    z-index: 10000;
    position: fixed;
    &:after {
        position: fixed;
        z-index: 9999;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.2);
    }
}

@media all and (max-width: 959px) {
    .choose_loader_wrapper {
        top: 35vh;
    }
}

@keyframes spin {   
    0% {     
        stroke-dashoffset: 628;
        transform: rotate(-90deg);
    }   
    50% {     
        stroke-dashoffset: 0;  
        transform: rotate(-90deg);
    } 
    100% {
        stroke-dashoffset: 628;
        transform: rotate(270deg);
    }
}

@keyframes balance {   
    0% {     
        transform: rotate(-25deg);
    }
    50% {     
        transform: rotate(25deg);
    }
    100% {
        transform: rotate(-25deg);
    }
}

@keyframes scales_part {   
    0% {     
        transform: rotate(25deg) translateY(0px);
    }
    25% {     
        transform: rotate(-10deg) translateY(0px);
    }
    50% {     
        transform: rotate(-25deg) translateX(-2px) translateY(0px);
    }
    75% {     
        transform: rotate(10deg) translateY(0px);
    }
    100% {
        transform: rotate(25deg) translateY(0px);
    }
}