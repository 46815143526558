$activeColor: #1e82f9;
$activeGradient: linear-gradient(135deg, #3b5cff 0%, #1eb8f2 100%);
$editColor: #94cf94;
$dangerColor: #df5d5d;
$cancelGradient: linear-gradient(135deg, #666 0%, #999 100%);
$warningColor: #df5d5d;

.CEO {
    &_Approval {
        $defaultHeight: 25px;
        position: relative;
        display: block;
        margin: auto;
        font-size: 12px;
        &.auc {
            width: 80px;
        }
        .CEO_Approval_dd {
            height: $defaultHeight;
            overflow: hidden;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
            width: 100%;
            border-radius: $defaultHeight/2;
            text-align: center;
            user-select: none;
            transition: all .2s linear ;
            z-index: 1;
            &.admin {
                cursor: pointer;
            }
            &__opened.admin {
                height: $defaultHeight * 3;
                border-radius: $defaultHeight/10;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                z-index: 2;
                .CEO_Approval_dd_item {
                    &:hover {
                        filter: brightness(.9);
                    }
                }
            }
            &_container {
                max-width: 100px;
                height: $defaultHeight;
                margin: 0 auto 5px;
                position: relative;
            }
            &_item {
                height: $defaultHeight;
                line-height: $defaultHeight;
                font-weight: 700;
                color: #fff;
                transition: all .2s linear;
                &.CEO_approved_Approved {
                    background: $editColor;
                }
                &.CEO_approved_Rejected {
                    background: $dangerColor;
                }
                &.CEO_approved_NA {
                    background: $cancelGradient;
                }
            }
            
        }
        
        &_background {
            position: fixed;
            top :0;
            left: 0;
            right: 0;
            bottom: 0;
            background: transparent;
            display: none;
            z-index: 1;
            &_visible {
                display: block;
            }
        }

        .CEO_Buttons {
            margin: 5px 0;
            button {
                height: $defaultHeight;
                padding: 0 5px;
                margin: 0 0 0 10px;
                color: #fff;
                font-weight: 600;
                &:hover {
                    filter: brightness(0.9);
                }
            }
            .CEO_SaveBtn {
                background: $activeColor;
            }
            .CEO_DiscardBtn {
                color: #666;
                background: none;
            }
        }
    }
    &_ApprovalLink {
        display: block;
        text-align: center;
        max-width: 100%;
        margin: 10px 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}


.ESSMainButtons {
    .CEO_Approval {
        margin: 0 20px;
        width: 100px;
        z-index: 11;
    }
    .CEO_ApprovalLink {
        margin: 0;
        display: block;
        white-space: nowrap;
    }
}